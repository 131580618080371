import { Box } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {boolean} [permanent]
 * @property {React.ReactNode} children
 */
/**
 * @param {Props} props
 */
export function Frame({ permanent, children }) {
  return (
    <Box
      borderWidth={permanent ? "1px" : { base: "0", xl: "1px" }}
      borderColor="gray.200"
      borderRadius="0.375rem"
      p={{ base: "0", xl: "1rem" }}>
      {children}
    </Box>
  );
}
