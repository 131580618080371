import {
  BOOKINGS_WORKFLOW_VALUE_SYSTEM,
  BOOKINGS_WORKFLOW_VALUE_DIRECT,
} from "../../../constants/bookings";
import { CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR } from "../../../constants/customerTravelers";

/**
 * @param {object} param0
 * @param {import("../../../types/Cart").CartCreateOrUpdateBooking} param0.booking
 * @param {keyof typeof import("../../../constants/carts").CARTS_WORKFLOWS} param0.workflow
 */
export function cartBookingGetServicesForSerialization({ booking, workflow }) {
  if (workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT) {
    return booking.services.map((service) => ({
      service_id: service.service_id,
      quantity: service.quantity,
      name: service.name,
      amount: service.unitary_amount,
      discount: service.discount,
    }));
  }

  if (workflow === BOOKINGS_WORKFLOW_VALUE_SYSTEM) {
    return booking.services.map((service) => ({
      service_id: service.service_id,
      quantity: service.quantity,
    }));
  }
}

/**
 * @param {object} param0
 * @param {import("../../../types/Cart").CartCreateOrUpdateBooking} param0.booking
 * @param {keyof typeof import("../../../constants/carts").CARTS_WORKFLOWS} param0.workflow
 */
export function cartBookingGetDiscountsForSerialization({ booking, workflow }) {
  if (workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT) {
    return booking.discounts?.map((discount) => ({
      discount_id: discount.discount_id,
      name: discount.name,
      amount: discount.amount,
    }));
  }

  return [];
}

/**
 * @param {object} param0
 * @param {import("../../../types/Cart").CartCreateDataFormValues} param0.fields
 * @param {keyof typeof import("../../../constants/carts").CARTS_WORKFLOWS} param0.workflow
 */
export default function cartsSerializeCreate({ fields, workflow }) {
  const guests = [
    {
      ...fields.data.main_guest,
      is_main: true,
      // Email field is optional in a direct booking
      email: fields.data.main_guest.email || undefined,
    },
    ...fields.data.guests.map((guest) => ({
      ...guest,
      type: CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR,
    })),
  ];

  const bookings = fields.data.bookings.map((booking) => ({
    ...booking,
    room_name: undefined,
    booking_id: undefined,
    services: cartBookingGetServicesForSerialization({ booking, workflow }),
    discounts: cartBookingGetDiscountsForSerialization({ booking, workflow }),
  }));

  return {
    data: {
      workflow,
      bookings: bookings,
      guests,
    },
  };
}
