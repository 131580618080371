import { CURRENCY_OPTIONS, PERCENT_OPTIONS } from "../../../../constants/intl";
import { ROOMS_SERVICES_AMOUNT_MODE_VALUE_PERCENT } from "../../../../constants/roomsServices";

/**
 * @typedef {object} PartialRoomService
 * @property {number} amount
 * @property {boolean} [is_charge]
 * @property {import("../../../../types/RoomService").ROOM_SERVICE_AMOUNT_MODE} amount_mode
 */

/**
 * Détermine le format d'affichage de la quantité (peut être en % ou en €)
 * @param {object} param0
 * @param {PartialRoomService} param0.service
 * @param {import("react-intl").IntlShape} param0.intl
 * @returns {string}
 */
export default function formatAmountByAmountMode({ service, intl }) {
  if (service.is_charge) {
    return "-";
  }
  return service?.amount_mode === ROOMS_SERVICES_AMOUNT_MODE_VALUE_PERCENT
    ? intl.formatNumber((service?.amount ?? 0) / 100, PERCENT_OPTIONS)
    : intl.formatNumber(service?.amount ?? 0, CURRENCY_OPTIONS);
}
