import { Button, Flex, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";
/**
 * @typedef {object} BookingRequestChangeFieldMessageProps
 * @property {string | number} newValue
 * @property {() => void} onAcceptBookingUpdateRequestByField
 */
/**
 * @param {BookingRequestChangeFieldMessageProps} props
 */
export function BookingRequestChangeFieldMessage({
  newValue,
  onAcceptBookingUpdateRequestByField,
}) {
  const intl = useIntl();

  return (
    <Flex gap=".5rem" alignItems="center">
      <Text variant="text-sm">
        {intl.formatMessage(
          {
            defaultMessage: "Modification demandée : {newValue}",
          },
          {
            newValue: newValue,
          },
        )}
      </Text>
      <Button
        size="xs"
        type="button"
        colorScheme="green"
        onClick={onAcceptBookingUpdateRequestByField}>
        {intl.formatMessage({ defaultMessage: "Accepter" })}
      </Button>
    </Flex>
  );
}
