// deps
import { defineMessage } from "react-intl";

// constants
import {
  BOOKINGS_WORKFLOW_VALUE_DIRECT,
  BOOKINGS_WORKFLOW_VALUE_SYSTEM,
  BOOKINGS_WORKFLOW_VALUE_PARTNER,
} from "./bookings";

// Liste des états.

export const ROOM_AVAILABILITIES_STATE_VALUE_AV = "av";
export const ROOM_AVAILABILITIES_STATE_VALUE_UN = "un";
export const ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_ONLINE =
  "un_sys_online";
export const ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_LSD = "un_sys_lsd";
export const ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_DIRECT =
  "un_sys_direct";
export const ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_PARTNER =
  "un_sys_partner";
export const ROOM_AVAILABILITIES_STATE_VALUE_UN_EXT = "un_ext";

export const roomAvailabilitiesStateMessage = defineMessage({
  defaultMessage: `{state, select,
    av {Disponible}
    un {Non disponible}
    un_ext {Réservé iCal}
    un_sys_online {Réservé clevacances.com}
    un_sys_lsd {Réservé clevacances.com (LSD)}
    un_sys_direct {Réservé direct}
    un_sys_partner {Réservé partenaire}
    other {{state}}
  }`,
});

export const ROOM_AVAILABILITIES_STATES = {
  [ROOM_AVAILABILITIES_STATE_VALUE_AV]: {
    id: ROOM_AVAILABILITIES_STATE_VALUE_AV,
    selectable: true,
    color: "#5BA32E",
  },
  [ROOM_AVAILABILITIES_STATE_VALUE_UN]: {
    id: ROOM_AVAILABILITIES_STATE_VALUE_UN,
    workflow: {
      [BOOKINGS_WORKFLOW_VALUE_DIRECT]: {
        color: "#9385bb",
        selectable: false,
      },
      [BOOKINGS_WORKFLOW_VALUE_SYSTEM]: {
        color: "#008CD2",
        selectable: false,
      },
      [BOOKINGS_WORKFLOW_VALUE_PARTNER]: {
        color: "#B77A33",
        selectable: false,
      },
    },
    remote: {
      color: "#493E3C",
      selectable: false,
    },
    selectable: true,
    color: "#C73905",
  },
  [ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_ONLINE]: {
    id: ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_ONLINE,
    selectable: false,
    color: "#008CD2",
  },
  [ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_LSD]: {
    id: ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_LSD,
    selectable: false,
    color: "#005178",
  },
  [ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_DIRECT]: {
    id: ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_DIRECT,
    selectable: false,
    color: "#9385bb",
  },
  [ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_PARTNER]: {
    id: ROOM_AVAILABILITIES_STATE_VALUE_UNAVAILABLE_SYS_PARTNER,
    selectable: false,
    color: "#B77A33",
  },

  [ROOM_AVAILABILITIES_STATE_VALUE_UN_EXT]: {
    id: ROOM_AVAILABILITIES_STATE_VALUE_UN_EXT,
    selectable: false,
    color: "#493E3C",
  },
};

export const ROOM_AVAILABILITIES_STATE_LIST = Object.values(
  ROOM_AVAILABILITIES_STATES,
);

// Liste des états à afficher pour la légende

export const ROOM_AVAILABILITIES_LEGEND_STATE_NC = "nc";
export const ROOM_AVAILABILITIES_LEGEND_STATE_AV = "av";
export const ROOM_AVAILABILITIES_LEGEND_STATE_UN = "un";
export const ROOM_AVAILABILITIES_LEGEND_STATE_BO = "bo";
export const ROOM_AVAILABILITIES_LEGEND_STATE_BO_LD = "bo_ld";
export const ROOM_AVAILABILITIES_LEGEND_STATE_BO_ICAL = "bo_ical";
export const ROOM_AVAILABILITIES_LEGEND_STATE_BO_PARTNER = "bo_partner";

export const ROOM_AVAILABILITIES_LEGEND_STATES = {
  [ROOM_AVAILABILITIES_LEGEND_STATE_NC]: {
    id: ROOM_AVAILABILITIES_LEGEND_STATE_NC,
    color: "#ccc",
  },
  [ROOM_AVAILABILITIES_LEGEND_STATE_BO]: {
    id: ROOM_AVAILABILITIES_LEGEND_STATE_BO,
    color: "#008CD2",
  },
  [ROOM_AVAILABILITIES_LEGEND_STATE_AV]: {
    id: ROOM_AVAILABILITIES_LEGEND_STATE_AV,
    color: "#5BA32E",
  },
  [ROOM_AVAILABILITIES_LEGEND_STATE_BO_LD]: {
    id: ROOM_AVAILABILITIES_LEGEND_STATE_BO_LD,
    color: "#9385bb",
  },
  [ROOM_AVAILABILITIES_LEGEND_STATE_UN]: {
    id: ROOM_AVAILABILITIES_LEGEND_STATE_UN,
    color: "#008CD2",
  },
  [ROOM_AVAILABILITIES_LEGEND_STATE_BO_ICAL]: {
    id: ROOM_AVAILABILITIES_LEGEND_STATE_BO_ICAL,
    color: "#493E3C",
  },
  [ROOM_AVAILABILITIES_LEGEND_STATE_BO_PARTNER]: {
    id: ROOM_AVAILABILITIES_LEGEND_STATE_BO_PARTNER,
    color: "#B77A33",
  },
};

export const ROOM_AVAILABILITIES_LEGEND_STATE_LIST = Object.values(
  ROOM_AVAILABILITIES_LEGEND_STATES,
);

// Modes d’affichage

export const ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_YEARLY = "yearly";
export const ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_MONTHLY = "monthly";
export const ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_YEARLY_MULTIPLE =
  "yearlyMultiple";

export const ROOM_AVAILABILITIES_FRONT_DISPLAY_MODES = {
  [ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_YEARLY]: {
    id: ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_YEARLY,
  },
  [ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_MONTHLY]: {
    id: ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_MONTHLY,
  },
  [ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_YEARLY_MULTIPLE]: {
    id: ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_VALUE_YEARLY_MULTIPLE,
  },
};

export const ROOM_AVAILABILITIES_FRONT_DISPLAY_MODE_LIST = Object.values(
  ROOM_AVAILABILITIES_FRONT_DISPLAY_MODES,
);

// Nombre d’hébergements à afficher par défaut dans le multi-vue.
export const ROOM_AVAILABILITIES_FRONT_MULTIVIEW_DEFAULT_ROOM_COUNT = 10;
