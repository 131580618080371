import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import FormControlRHF from "../../../../../../components/ReactHookForm/FormControlRHF";
import FormObserver from "../../../../../../components/ReactHookForm/FormObserver";
import { CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM } from "../../../../../../constants/bookings";
import { ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY } from "../../../../../../constants/roomsServices";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {(service) => void} onSuccess
 * @property {number} [minQuantity]
 * @property {number} [maxQuantity]
 * @property {import("../../../../../../types/Cart").CartCreateBookingService} [initialService]
 * @property {keyof typeof import("../../../../../../constants/carts").CARTS_WORKFLOWS} workflow
 */

/**
 * @param {Props} props
 */
export function CreateOrUpdateBookingServiceModal({
  isOpen,
  onClose,
  onSuccess,
  initialService,
  workflow,
}) {
  const intl = useIntl();

  const form = useForm({
    defaultValues: /**
      @type {{ name: string, amount: number, unitary_amount: number, discount: number, quantity: number, uuid: string, strategy: string, max_quantity: number }} 
    */ ({
      name: initialService?.name ?? "",
      amount: initialService?.amount ?? "",
      unitary_amount: initialService?.unitary_amount ?? "",
      discount: initialService?.discount ?? "",
      quantity: initialService?.min_quantity
        ? initialService?.min_quantity
        : initialService?.quantity ?? 1,
      uuid: initialService?.uuid ?? "",
      strategy: initialService?.strategy ?? "",
      max_quantity: initialService?.max_quantity ?? "",
    }),
  });
  const { handleSubmit, control } = form;

  const minQuantity = initialService?.min_quantity;

  const maxQuantity = initialService?.max_quantity;

  function onSubmit(values) {
    onSuccess(values);
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onCloseComplete={() => form.reset()}
      onClose={onClose}
      scrollBehavior="inside"
      size="2xl"
      blockScrollOnMount={false}>
      <ModalOverlay />

      <ModalContent>
        <FormProvider {...form}>
          <ModalCloseButton />

          <ModalHeader>
            {initialService ? (
              <FormattedMessage defaultMessage="Modifier un service" />
            ) : (
              <FormattedMessage defaultMessage="Ajouter un service" />
            )}
          </ModalHeader>

          <ModalBody>
            <form
              id="createService"
              onSubmit={(e) => {
                // Prevent the submission of nested form like in hotel payment mode form
                e.preventDefault();
                handleSubmit(onSubmit)();
                e.stopPropagation();
              }}
              autoComplete="off"
              noValidate>
              <Stack spacing="1rem">
                <FormControlRHF
                  isDisabled={workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM}
                  control={control}
                  label={intl.formatMessage({
                    defaultMessage: "Nom du service",
                  })}
                  rules={{
                    required: true,
                  }}
                  name="name"
                />

                <FormControlRHF
                  control={control}
                  isDisabled={workflow === CARTS_BOOKINGS_WORKFLOW_VALUE_SYSTEM}
                  type="number-input"
                  label={intl.formatMessage({
                    defaultMessage: "Montant unitaire TTC",
                  })}
                  rules={{
                    required: true,
                    min: 0,
                  }}
                  name="unitary_amount"
                />

                <FormObserver control={form.control} name="strategy">
                  {(strategy) => (
                    <>
                      {strategy ===
                        ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY && (
                        <FormControlRHF
                          control={control}
                          type="number-input"
                          name="max_quantity"
                          label={intl.formatMessage({
                            defaultMessage: "Quantité maximale",
                          })}
                          rules={{
                            required: true,
                          }}
                        />
                      )}
                    </>
                  )}
                </FormObserver>

                <FormObserver control={form.control} name="amount">
                  {(amount) => (
                    <FormControlRHF
                      control={control}
                      type="number-input"
                      label={intl.formatMessage({
                        defaultMessage: "Remise unitaire TTC (en euros)",
                      })}
                      rules={{
                        max: amount,
                      }}
                      name="discount"
                    />
                  )}
                </FormObserver>

                <FormControlRHF
                  control={control}
                  rules={{
                    required: true,
                    min: minQuantity ? minQuantity : 0,
                    max: maxQuantity ? maxQuantity : undefined,
                  }}
                  type="number-input"
                  label={intl.formatMessage({
                    defaultMessage: "Quantité",
                  })}
                  name="quantity"
                />
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup size="sm">
              <Button>
                <FormattedMessage defaultMessage="Fermer" />
              </Button>

              <Button
                type="submit"
                form="createService"
                colorScheme="brandPrimary">
                {initialService ? (
                  <FormattedMessage defaultMessage="Modifier" />
                ) : (
                  <FormattedMessage defaultMessage="Ajouter" />
                )}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
