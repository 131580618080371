import { Box, Flex, IconButton, Text, useDisclosure } from "@chakra-ui/react";
import { BOOKINGS_WORKFLOW_VALUE_DIRECT } from "../../../../../constants/bookings";
import { CURRENCY_OPTIONS } from "../../../../../constants/intl";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Icon } from "../../../../../components/Icon";

import { CreateOrUpdateBookingDiscountModal } from "./CreateOrUpdateBookingDiscountModal";
import FormErrorObserverRHF from "../../../../../components/ReactHookForm/FormErrorObserverRHF";
import FormErrorMessageRHF from "../../../../../components/ReactHookForm/FormErrorMessageRHF";
/**
 * @typedef {object} Props
 * @property {import("../../../../../types/Cart").CartCreateBookingDiscount} discount
 * @property {number} bookingIndex
 * @property {number} discountIndex
 * @property {import("react-hook-form").UseFieldArrayRemove} discountRemove
 * @property {import("react-hook-form").UseFieldArrayUpdate<import("../../../../../types/Cart").CartCreateDataFormValues, any>} discountUpdate
 * @property {keyof typeof import("../../../../../constants/carts").CARTS_WORKFLOWS} workflow
 * @property {(data?: import("../useBookingEstimate").RequestEstimateParams) => void} requestEstimate
 */

/**
 * @param {Props} props
 */
export function BookingDiscount({
  discount,
  bookingIndex,
  discountIndex,
  discountRemove,
  discountUpdate,
  workflow,
  requestEstimate,
}) {
  const intl = useIntl();

  const { getValues } = useFormContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  function onModalSuccess(discount) {
    const foundDiscount = getValues(
      `data.bookings.${bookingIndex}.discounts.${discountIndex}`,
    );

    discountUpdate(discountIndex, { ...foundDiscount, ...discount });

    requestEstimate();
  }

  return (
    <>
      <Box py=".5rem">
        <Flex
          justifyContent="space-between"
          background="gray.100"
          p=".75rem"
          borderRadius="8px">
          <Box>
            <Text variant="h6">{discount?.name}</Text>
          </Box>

          <Flex gap="1rem" alignItems="center">
            <Text>
              {intl.formatNumber(discount?.amount ?? 0, CURRENCY_OPTIONS)}
            </Text>

            {workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT &&
              !discount.discount_id && (
                <IconButton
                  icon={<Icon icon="ms_delete" size="1.25rem" />}
                  variant="outline"
                  size="sm"
                  colorScheme="red"
                  onClick={() => discountRemove(discountIndex)}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Supprimer",
                  })}
                />
              )}

            {workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT && (
              <IconButton
                icon={<Icon icon="ms_edit" size="1.25rem" />}
                variant="outline"
                size="sm"
                onClick={onOpen}
                aria-label={intl.formatMessage({
                  defaultMessage: "Modifier",
                })}
              />
            )}
          </Flex>
        </Flex>

        <FormErrorObserverRHF
          name={`data.bookings.${bookingIndex}.discounts.${discountIndex}.amount`}
          render={({ error, hasError }) => {
            return hasError && <FormErrorMessageRHF error={error} />;
          }}
        />
      </Box>

      <CreateOrUpdateBookingDiscountModal
        isOpen={isOpen}
        onClose={onClose}
        initialDiscount={discount}
        onSuccess={onModalSuccess}
      />
    </>
  );
}
