import { Box } from "@chakra-ui/react";
import { CELL_WIDTH } from "./Month";

import { memo } from "react";

export const EmptyCell = memo(
  /**
   * @typedef {object} Props
   * @property {boolean} isFirst
   * @property {boolean} isFirstRow
   * @property {boolean} isLastRow
   */
  /**
   * @param {Props} props
   */
  function EmptyCell({ isFirst, isFirstRow, isLastRow }) {
    return (
      <Box
        w={CELL_WIDTH}
        h={CELL_WIDTH}
        borderLeftWidth="1px"
        borderBottomWidth="1px"
        borderLeftColor={isFirst ? undefined : "transparent"}
        borderBottomColor={isFirstRow || isLastRow ? undefined : "transparent"}
      />
    );
  },
);
