import { Stack } from "@chakra-ui/react";
import ObserverRHF from "../../../../components/ReactHookForm/ObserverRHF";
import { useCallback, useEffect, useRef, useState } from "react";
import { useFieldArray } from "react-hook-form";
import { BookingSummary } from "../summary/BookingSummary";
import { BookingFields } from "./BookingFields";
import { BookingCharges } from "./charge/BookingCharges";
import { BookingDiscounts } from "./discount/BookingDiscounts";
import { BookingServices } from "./service/BookingServices";
import { useBookingEstimate } from "./useBookingEstimate";
import { BookingWarningAlert } from "./warningAlert/WarningAlert";
import { useApiError } from "../../../../components/ReactHookForm/ApiError";
import getBookingSummary from "../../../../helpers/bookings/getBookingSummary";

/**
 * @typedef {object} Props
 * @property {number} bookingIndex
 * @property {import("react-hook-form").UseFieldArrayRemove} bookingsRemove
 * @property {keyof typeof import("../../../../constants/carts").CARTS_WORKFLOWS} workflow
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../../../types/Cart").BookingSummary[]>>} setBookingSummaries
 * @property {import("react-hook-form").UseFieldArrayUpdate<import("../../../../types/Cart").CartCreateDataFormValues, "data.bookings">} bookingsUpdate
 * @property {import("react-hook-form").UseFormReturn<import("../../../../types/Cart").CartCreateDataFormValues>} form
 */

/**
 * @param {Props} props
 */
export function BookingCard({
  bookingIndex,
  bookingsRemove,
  workflow,
  setBookingSummaries,
  form,
}) {
  const { control, getValues, setValue } = form;

  const { resolveApiError } = useApiError();

  const [apiErrorDetail, setApiErrorDetail] = useState(
    /** @type {string | null} */ (null),
  );

  const [warnings, setWarnings] = useState([]);

  const {
    fields: services,
    append: servicesAppend,
    remove: servicesRemove,
    replace: servicesReplace,
    update: servicesUpdate,
  } = useFieldArray({
    name: `data.bookings.${bookingIndex}.services`,
    control,
  });

  const {
    fields: discounts,
    append: discountsAppend,
    remove: discountsRemove,
    replace: discountsReplace,
    update: discountsUpdate,
  } = useFieldArray({
    name: `data.bookings.${bookingIndex}.discounts`,
    control,
  });

  const { fields: charges, replace: chargesReplace } = useFieldArray({
    name: `data.bookings.${bookingIndex}.charges`,
    control,
  });

  const [availableServices, setAvailableServices] = useState(
    /** @type {import("../../../../types/Cart").CartCreateBookingService[]} */ ([]),
  );

  const isMounted$ = useRef(false);

  const setBooking = useCallback(
    /** @type {import("../../Form/booking/useBookingEstimate").BookingEstimateSetBooking} */
    (bookingFormValues) => {
      setValue(`data.bookings.${bookingIndex}`, bookingFormValues, {
        shouldDirty: true,
      });
    },
    [bookingIndex, setValue],
  );

  const getBooking = useCallback(
    /** @type {import("../../Form/booking/useBookingEstimate").BookingEstimateGetBooking} */
    () => {
      return getValues(`data.bookings.${bookingIndex}`);
    },
    [bookingIndex, getValues],
  );

  const getPeriodsAmount = useCallback(
    /** @type {import("../../Form/booking/useBookingEstimate").BookingEstimateGetPeriodsAmount} */
    () => {
      return getValues(`data.bookings.${bookingIndex}.periods_amount`);
    },
    [bookingIndex, getValues],
  );

  const getDiscountList = useCallback(
    /** @type {import("../../Form/booking/useBookingEstimate").BookingEstimateGetDiscountList} */
    () => {
      return getValues(`data.bookings.${bookingIndex}.discounts`);
    },
    [bookingIndex, getValues],
  );

  const replaceDiscounts = useCallback(
    /** @type {import("../../Form/booking/useBookingEstimate").BookingEstimateDiscountReplaceCallback} */
    (discounts) => {
      discountsReplace(discounts);
    },
    [discountsReplace],
  );

  const replaceServices = useCallback(
    /** @type {import("../../Form/booking/useBookingEstimate").BookingEstimateServicesReplaceCallback} */
    (services) => {
      servicesReplace(services);
    },
    [servicesReplace],
  );

  const replaceCharges = useCallback(
    /** @type {import("../../Form/booking/useBookingEstimate").BookingEstimateChargesReplaceCallback} */
    (charges) => {
      chargesReplace(charges);
    },
    [chargesReplace],
  );

  const resolveApiErrorCallback = useCallback(
    /** @type {import("../../Form/booking/useBookingEstimate").BookingEstimateResolveApiErrorCallback} */
    (errorFields, errorDetail) => {
      const response = {
        body: {
          errors: [
            {
              detail: errorDetail,
              meta: {
                fields: {
                  data: {
                    bookings: {
                      [bookingIndex]: {
                        ...errorFields.data,
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      };

      resolveApiError({ response, form });
    },
    [bookingIndex, form, resolveApiError],
  );

  const { requestEstimate, isLoading: isEstimateLoading } = useBookingEstimate({
    setBooking,
    getBooking,
    getPeriodsAmount,
    getDiscountList,
    resolveApiError: resolveApiErrorCallback,
    clearErrors: form.clearErrors,
    workflow,
    setBookingSummaries,
    setAvailableServices,
    bookingDiscountsReplace: replaceDiscounts,
    bookingChargesReplace: replaceCharges,
    bookingServicesReplace: replaceServices,
    setApiErrorDetail,
    setWarnings,
  });

  useEffect(() => {
    if (!isMounted$.current) {
      isMounted$.current = true;
      requestEstimate();
    }
  }, [requestEstimate]);

  const bookingSummary = getBookingSummary({
    data: null,
    bookingFormValues: getValues(`data.bookings.${bookingIndex}`),
  });

  return (
    <Stack spacing="1rem">
      <ObserverRHF
        names={[
          `data.bookings.${bookingIndex}.room_id`,
          `data.bookings.${bookingIndex}.checkin`,
          `data.bookings.${bookingIndex}.checkout`,
          `data.bookings.${bookingIndex}.adults`,
        ]}
        render={({ values: [roomId, checkin, checkout, adults] }) => {
          const areFieldsMissing = !roomId || !checkin || !checkout || !adults;

          return (
            <>
              <BookingFields
                bookingIndex={bookingIndex}
                bookingsRemove={bookingsRemove}
                requestEstimate={requestEstimate}
                areFieldsMissing={areFieldsMissing}
                roomId={roomId}
                workflow={workflow}
              />

              <BookingWarningAlert
                apiErrorDetail={apiErrorDetail}
                warnings={warnings}
                isEstimateLoading={isEstimateLoading}
              />

              <BookingServices
                bookingIndex={bookingIndex}
                workflow={workflow}
                displayAddButton={!areFieldsMissing}
                isLoading={isEstimateLoading}
                availableServices={availableServices}
                services={services}
                // @ts-ignore
                servicesAppend={servicesAppend}
                servicesRemove={servicesRemove}
                // @ts-ignore
                servicesUpdate={servicesUpdate}
                requestEstimate={requestEstimate}
              />

              <BookingCharges charges={charges} isLoading={isEstimateLoading} />

              <BookingDiscounts
                bookingIndex={bookingIndex}
                workflow={workflow}
                displayAddButton={!areFieldsMissing}
                isLoading={isEstimateLoading}
                discounts={discounts}
                discountsAppend={discountsAppend}
                discountsRemove={discountsRemove}
                discountsUpdate={discountsUpdate}
                requestEstimate={requestEstimate}
              />

              <BookingSummary bookingSummary={bookingSummary} />
            </>
          );
        }}
      />
    </Stack>
  );
}
