import { Box, Flex, Stack, Text, Tooltip } from "@chakra-ui/react";
import {
  CURRENCY_OPTIONS,
  SHORT_DATE_OPTIONS,
} from "../../../../constants/intl";
import { bookingsGetNumberOfNights } from "../../../../helpers/bookings";
import { useIntl } from "react-intl";
import { Icon } from "../../../../components/Icon";
import getBookingSummary from "../../../../helpers/bookings/getBookingSummary";

/**
 * @typedef {object} Props
 * @property {import("../../../../types/Cart").CartCreateFormValues} data
 * @property {import("../../../../types/Cart").BookingSummary[]} bookingSummaries
 */

/**
 * @param {Props} props
 */
export function CartSummary({ data, bookingSummaries }) {
  const intl = useIntl();

  function cartGetTotal() {
    const cartTotal = bookingSummaries?.reduce(
      (acc, item) => ({
        total:
          acc.total +
          acc.touristTaxAmount +
          (item.total + (item.touristTaxAmount ?? 0)),
        touristTaxAmount: 0,
      }),
      { total: 0, touristTaxAmount: 0 },
    );

    return cartTotal?.total ?? 0;
  }

  return (
    <Stack spacing="1rem" px="1.5rem">
      {data.bookings?.map((booking, index) => {
        const bookingSummary = getBookingSummary({
          data: null,
          bookingFormValues: booking,
        });

        return (
          <Stack
            key={index}
            spacing="0.5rem"
            py="1rem"
            borderBottom="1px solid"
            borderBottomColor="gray.200">
            <Flex
              justifyContent="space-between"
              gap={{ base: "1rem", md: ".5rem" }}
              flexDir={{ base: "column", md: "row" }}>
              <Box>
                <Text variant="h4" color="gray.500">
                  {intl.formatMessage(
                    { defaultMessage: "Réservation n°{index} - {roomName}" },
                    { index: index + 1, roomName: booking.room_name },
                  )}
                </Text>

                <Flex gap="1rem">
                  {booking.checkin && booking.checkout && (
                    <Text variant="text-sm" color="gray.500">
                      {intl.formatMessage(
                        { defaultMessage: "Du {checkin} au {checkout}" },
                        {
                          checkin: intl.formatDate(
                            booking.checkin,
                            SHORT_DATE_OPTIONS,
                          ),
                          checkout: intl.formatDate(
                            booking.checkout,
                            SHORT_DATE_OPTIONS,
                          ),
                        },
                      )}
                    </Text>
                  )}

                  <Text variant="text-sm">
                    {intl.formatMessage(
                      {
                        defaultMessage:
                          "{adults, plural, one {1 adulte} other {# adultes}}",
                      },
                      { adults: booking.adults },
                    )}
                  </Text>
                </Flex>
              </Box>

              <Flex gap="2rem" alignItems="center" textAlign="end">
                {!isNaN(bookingSummary?.periodsAmountRaw ?? 0) && (
                  <Box>
                    <Text>
                      {intl.formatMessage({
                        defaultMessage: "Montant des nuits",
                      })}
                    </Text>

                    <Text>
                      {intl.formatNumber(
                        bookingSummary?.periodsAmountRaw ?? 0,
                        CURRENCY_OPTIONS,
                      )}
                    </Text>
                  </Box>
                )}

                {!isNaN(bookingSummary?.periodsAmountDiscount ?? 0) && (
                  <Box>
                    <Text>
                      {intl.formatMessage({
                        defaultMessage: "Remise",
                      })}
                    </Text>

                    <Text>
                      {intl.formatNumber(
                        bookingSummary?.periodsAmountDiscount ?? 0,
                        CURRENCY_OPTIONS,
                      )}
                    </Text>
                  </Box>
                )}

                <Box>
                  <Text variant="h4" color="brandPrimary.500">
                    {intl.formatMessage({ defaultMessage: "Total" })}
                  </Text>

                  <Text color="brandPrimary.500">
                    {intl.formatMessage(
                      { defaultMessage: "{total} €" },
                      { total: bookingSummary?.periodsAmount ?? 0 },
                    )}
                  </Text>
                </Box>
              </Flex>
            </Flex>

            {bookingSummaries[index]?.touristTaxAmount !== null && (
              <Flex justifyContent="space-between" alignItems="center">
                <Box>
                  <Text variant="h5">
                    {intl.formatMessage(
                      {
                        defaultMessage:
                          "Taxe de séjour : {nbNight, plural, one {1 nuitée} other {# nuitées}} par adultes",
                      },
                      {
                        nbNight: bookingsGetNumberOfNights({
                          booking,
                        }),
                      },
                    )}
                  </Text>

                  <Text variant="text-sm" color="brandPrimary.500">
                    {intl.formatMessage({
                      defaultMessage:
                        "Dans le cadre de la réception d'un paiement sur place, le propriétaire devra par la suite reverser la taxe de séjour à la commune",
                    })}
                  </Text>
                </Box>

                <Text variant="h5">
                  {/* Only the bookingSummaries have the correct tourist tax since its been calculated from estimate values */}
                  {bookingSummaries[index]?.touristTaxAmount === 0
                    ? intl.formatMessage({
                        defaultMessage: "Exonérée",
                      })
                    : intl.formatNumber(
                        bookingSummaries[index]?.touristTaxAmount ?? 0,
                        CURRENCY_OPTIONS,
                      )}
                </Text>
              </Flex>
            )}

            {booking?.services?.length > 0 && (
              <Stack spacing=".5rem" pl=".5rem">
                <Text>
                  {intl.formatMessage({ defaultMessage: "Suppléments" })}
                </Text>

                {booking?.services.map((service, index) => (
                  <Flex justifyContent="space-between" key={index} pl=".5rem">
                    <Text>
                      {intl.formatMessage(
                        { defaultMessage: "{serviceName} (x{quantity})" },
                        {
                          serviceName: service.name,
                          quantity: service.quantity,
                        },
                      )}
                    </Text>

                    {service.total && (
                      <Text>
                        {intl.formatNumber(service.total, CURRENCY_OPTIONS)}
                      </Text>
                    )}
                  </Flex>
                ))}
              </Stack>
            )}

            {booking.charges?.length > 0 && (
              <Stack spacing=".5rem" pl=".5rem">
                <Flex gap=".5rem" alignItems="center">
                  <Text>
                    {intl.formatMessage({ defaultMessage: "Charges" })}
                  </Text>

                  <Tooltip
                    label={intl.formatMessage({
                      defaultMessage:
                        "Le montant des charges sera calculé à la fin du séjour",
                    })}>
                    <Box>
                      <Icon icon="ms_help" />
                    </Box>
                  </Tooltip>
                </Flex>

                {booking.charges.map((charge, index) => (
                  <Flex justifyContent="space-between" key={index} pl=".5rem">
                    <Box>
                      <Text>{charge.name}</Text>

                      <Text>{charge.about}</Text>
                    </Box>

                    <Text>-</Text>
                  </Flex>
                ))}
              </Stack>
            )}

            <Flex justifyContent="space-between">
              <Text variant="h4">
                {intl.formatMessage({
                  defaultMessage: "Total de la réservation",
                })}
              </Text>

              <Text variant="h4" fontWeight="500" color="brandPrimary.500">
                {intl.formatNumber(
                  (bookingSummary?.total ?? 0) +
                    (bookingSummary?.touristTaxAmount ?? 0),
                  CURRENCY_OPTIONS,
                )}
              </Text>
            </Flex>
          </Stack>
        );
      })}

      {data.bookings?.length > 1 && (
        <Flex justifyContent="space-between">
          <Text variant="h3">
            {intl.formatMessage({
              defaultMessage: "Montant total du panier",
            })}
          </Text>

          {bookingSummaries && (
            <Text variant="h3" fontWeight="500" color="brandPrimary.500">
              {intl.formatNumber(cartGetTotal(), CURRENCY_OPTIONS)}
            </Text>
          )}
        </Flex>
      )}
    </Stack>
  );
}
