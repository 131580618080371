import {
  Button,
  Flex,
  IconButton,
  Select,
  SimpleGrid,
  Stack,
  Text,
} from "@chakra-ui/react";
import FormControlRHF from "../../../../components/ReactHookForm/FormControlRHF";
import {
  customerTravelersGenderMessage,
  CUSTOMER_TRAVELERS_GENDER_LIST,
} from "../../../../constants/customerTravelers";
import capitalize from "@splitfire-agency/raiden-library/dist/libraries/utils/capitalize";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { Icon } from "../../../../components/Icon";
import { Frame } from "../../../../components/Frame";

export function BookingsCustomerGuestList() {
  const {
    fields: guests,
    append: guestAppend,
    remove: guestRemove,
  } = useFieldArray({ name: "data.guests" });

  /** @type {import("react-hook-form").UseFormReturn<import("../../../../types/Cart").CartCreateDataFormValues>} */
  const { control } = useFormContext();

  const intl = useIntl();

  return (
    <>
      <Stack spacing="1rem">
        {guests.length > 0 && (
          <Text variant="h5" color="gray.500">
            {intl.formatMessage({ defaultMessage: "Liste des contacts" })}
          </Text>
        )}

        {guests.map((_, index) => (
          <Frame key={index}>
            <SimpleGrid
              columns={{ base: 1, md: 5 }}
              gridTemplateColumns={{ base: "1fr", md: ".6fr 1fr 1fr 1fr auto" }}
              spacing="1rem"
              alignItems="flex-end">
              <FormControlRHF
                control={control}
                label={intl.formatMessage({
                  defaultMessage: "Civilité",
                })}
                rules={{
                  required: true,
                }}
                name={`data.guests.${index}.gender`}
                renderWithFormControl={(fields) => (
                  <Select {...fields}>
                    <option value="">{"-"}</option>

                    {CUSTOMER_TRAVELERS_GENDER_LIST.map((item) => (
                      <option key={item.id} value={item.id}>
                        {capitalize(
                          intl.formatMessage(customerTravelersGenderMessage, {
                            gender: item.id,
                          }),
                        )}
                      </option>
                    ))}
                  </Select>
                )}
              />

              <FormControlRHF
                control={control}
                name={`data.guests.${index}.lastname`}
                label={intl.formatMessage({
                  defaultMessage: "Nom",
                })}
                rules={{
                  required: true,
                }}
              />

              <FormControlRHF
                control={control}
                name={`data.guests.${index}.firstname`}
                label={intl.formatMessage({
                  defaultMessage: "Prénom",
                })}
                rules={{
                  required: true,
                }}
              />

              <FormControlRHF
                control={control}
                type="datetime-picker"
                name={`data.guests.${index}.birthdate`}
                label={intl.formatMessage({
                  defaultMessage: "Date de naissance",
                })}
                inputProps={{
                  minWidth: "100%",
                  enableKeyboardInput: true,
                }}
              />

              <IconButton
                maxW="2.5rem"
                mb={{ base: "0", md: ".25rem" }}
                mt=".5rem"
                colorScheme="red"
                size="sm"
                aria-label={intl.formatMessage({
                  defaultMessage: "Supprimer la ligne",
                })}
                icon={<Icon icon="ms_delete" />}
                onClick={function () {
                  guestRemove(index);
                }}
              />
            </SimpleGrid>
          </Frame>
        ))}

        <Flex justifyContent="flex-end">
          <Button
            maxWidth="10rem"
            variant="outline"
            size="sm"
            onClick={() => {
              guestAppend({
                birthdate: "",
                firstname: "",
                lastname: "",
                gender: "",
              });
            }}
            leftIcon={<Icon icon="ms_add" />}>
            {intl.formatMessage({
              defaultMessage: "Ajouter un contact",
            })}
          </Button>
        </Flex>
      </Stack>
    </>
  );
}
