import { Box, HStack, Stack } from "@chakra-ui/react";
import dayjs from "dayjs";
import { Fragment, memo, useMemo } from "react";
import { ROOM_AVAILABILITIES_STATE_VALUE_AV } from "../../../constants/roomAvailabilities";
import { Cell } from "./Cell";
import { EmptyCell } from "./EmptyCell";
import { MonthHeader } from "./MonthHeader";

export const CELL_WIDTH = "50px";

export const Month = memo(
  /**
   * @typedef {object} Props
   * @property {import("dayjs").Dayjs} startDateMonth
   * @property {import("./types").VisualizationMonth} [visualizationDataMonth]
   * @property {"checkin" | "checkout"} mode
   * @property {import("./types").CheckoutsDataMonth | null} checkoutsMonthData
   * @property {import("dayjs").Dayjs | null} startDateSelection
   * @property {import("dayjs").Dayjs | null} endDateSelection
   * @property {string[]} selectedDates
   * @property {boolean} withPrices
   * @property {boolean} isValidating
   * @property {() => void} [onClickPrev]
   * @property {() => void} [onClickNext]
   * @property {number} monthIndex
   * @property {number} monthsCount
   * @property {boolean} preventPast prevent user from going into the past by disabling prev month button
   */
  /**
   * @param {Props} props
   */
  function Month({
    startDateMonth,
    visualizationDataMonth,
    mode,
    checkoutsMonthData,
    startDateSelection,
    endDateSelection,
    selectedDates,
    withPrices,
    isValidating,
    onClickPrev,
    onClickNext,
    monthIndex,
    monthsCount,
    preventPast,
  }) {
    const startDateMonthIso = useMemo(() => {
      return startDateMonth.toISOString();
    }, [startDateMonth]);

    const startOffset = useMemo(() => {
      const offset = startDateMonth.day() - 1;
      return offset < 0 ? 6 : offset;
    }, [startDateMonth]);

    const daysInMonth = useMemo(() => {
      return startDateMonth.daysInMonth();
    }, [startDateMonth]);

    const rowsCount = useMemo(() => {
      return 6;
    }, []);

    const days = useMemo(() => {
      /** @type {Record<number, any>} */
      const days = {};
      for (let i = 0; i < daysInMonth; i++) {
        const date = startDateMonth.add(i, "day");
        days[i + 1] = {
          date,
          dateString: date.format("YYYY-MM-DD"),
          dayOfMonth: date.format("DD"),
          isDayInThePast: date.isBefore(dayjs().startOf("day"), "day"),
          uid: date.format("YYYY-MM-DD"),
        };
      }
      return days;
    }, [daysInMonth, startDateMonth]);

    return (
      <Stack spacing="8px">
        <MonthHeader
          startDateMonthIso={startDateMonthIso}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          monthIndex={monthIndex}
          monthsCount={monthsCount}
          preventPast={preventPast}
        />

        <Box
          borderTopWidth="1px"
          borderRightWidth="1px"
          pointerEvents={isValidating ? "none" : undefined}
          opacity={isValidating ? 0.5 : undefined}
          transition={!isValidating ? "opacity 0.25s" : "none"}>
          {Array.from({ length: rowsCount }, (_, rowIndex) => {
            return (
              <HStack spacing="0" key={rowIndex}>
                {Array.from({ length: 7 }, (_, dayIndex) => {
                  const day = rowIndex * 7 + (dayIndex - startOffset) + 1;
                  return (
                    <Fragment key={day}>
                      {day < 1 || day > daysInMonth ? (
                        <EmptyCell
                          isFirst={dayIndex === 0 || day === daysInMonth + 1}
                          isFirstRow={rowIndex === 0}
                          isLastRow={rowIndex === rowsCount - 1}
                          key={day}
                        />
                      ) : (
                        <Cell
                          dayOfMonth={days[day].dayOfMonth}
                          cell={visualizationDataMonth?.[days[day].dayOfMonth]}
                          yearMonthDay={days[day].dateString}
                          checkoutDetail={
                            checkoutsMonthData?.[days[day].dayOfMonth] ?? null
                          }
                          isCheckin={Boolean(
                            mode === "checkin" &&
                              visualizationDataMonth?.[days[day].dayOfMonth]
                                ?.currentCell?.is_checkin_day &&
                              visualizationDataMonth?.[days[day].dayOfMonth]
                                ?.currentCell?.state ===
                                ROOM_AVAILABILITIES_STATE_VALUE_AV,
                          )}
                          isStartSelection={
                            startDateSelection !== null &&
                            days[day].dateString ===
                              startDateSelection.format("YYYY-MM-DD")
                          }
                          isEndSelection={
                            endDateSelection !== null &&
                            days[day].dateString ===
                              endDateSelection.format("YYYY-MM-DD")
                          }
                          isSelected={selectedDates.includes(
                            days[day].dateString,
                          )}
                          withPrices={withPrices}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </HStack>
            );
          })}
        </Box>
      </Stack>
    );
  },
);
