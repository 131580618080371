import { forwardRef, useCallback } from "react";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import Autocomplete from "../Autocomplete";
import { Option } from "./Option";

/**
 * @type {import("../Autocomplete").AutocompleteRenderOption}
 */
function getCustomerTravelerOption(params) {
  return <Option {...params} />;
}

/**
 * Determine le name à afficher
 * @param {object} param0
 * @param {import("../../../types/User").UserGuest} param0.guest
 * @returns {string}
 */
function getName({ guest }) {
  let name = "";

  if (guest?.firstname) {
    name = guest.firstname;
  }

  if (guest?.lastname) {
    name = name ? `${name} ${guest.lastname}` : guest.lastname;
  }

  return name;
}

export const CustomerTravelersAutocomplete = forwardRef(
  /**
   * @typedef {object} Props
   * @property {string} [environmentId]
   * @property {object} [queryParams]
   */
  /**
   * @param {Props & import("../Autocomplete").AutocompleteCommonProps<string>} props
   */
  function CustomerTravelersAutocomplete(
    { environmentId, queryParams = {}, ...otherProps },
    ref,
  ) {
    const resolveItemValue = useCallback(({ item }) => String(item?.id), []);

    const resolveItemName = useCallback(
      ({ item }) => getName({ guest: item }),
      [],
    );

    const generateUrl = useCallback(
      ({ page, fields }) =>
        generateApiUrl({
          id: "@customersTravelers.viewAny",
          query: {
            page,
            per_page: 100,
            environment_id: environmentId,
            ...fields,
            ...queryParams,
          },
        }),
      [environmentId, queryParams],
    );

    const generateHydrationUrl = useCallback(
      ({ valuesToHydrate }) =>
        generateApiUrl({
          id: "@customersTravelers.viewAny",
          query: {
            only_id: valuesToHydrate,
          },
        }),
      [],
    );

    return (
      <Autocomplete
        ref={ref}
        generateUrl={generateUrl}
        generateHydrationUrl={generateHydrationUrl}
        resolveItemValue={resolveItemValue}
        resolveItemName={resolveItemName}
        renderOption={getCustomerTravelerOption}
        isMultiple={false}
        {...otherProps}
      />
    );
  },
);
