import {
  Alert,
  AlertDescription,
  Box,
  Collapse,
  HStack,
  Skeleton,
  Spinner,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import { DataHandler } from "../../../components/DataHandler";
import { FormAutoSubmit } from "../../../components/Form/AutoSubmit";
import { RGrid } from "../../../components/RGrid";
import { useApi } from "../../../hooks/useApi";
import generateApiUrl from "../../../libraries/utils/generateApiUrl";
import { BookingCreateModal } from "../../Booking/Create";
import { RoomsVisualizationCalendar } from "./Calendar";
import { Filters } from "./Filters";

const MONTHS_COUNT_DESKTOP = 3;
const MONTHS_COUNT_MOBILE = 1;

/**
 * @param {object} params
 * @param {import("./types").VisualizationDataSet} params.data
 * @param {string} params.yearMonth
 * @param {string} params.day
 * @returns {import("./types").VisualizationCell | null}
 */
export function getCell({ data, yearMonth, day }) {
  /** @type {import("./types").VisualizationCell | undefined} */
  const cell = data?.[yearMonth]?.[day];
  return cell ?? null;
}

/**
 * @typedef {object} RoomsVisualizationFormValues
 * @property {number} roomId
 * @property {string} yearMonth
 * @property {number} adults
 * @property {number} children
 * @property {number} babies
 * @property {number} pets
 */

/**
 * @param {object} params
 * @param {number} params.initialRoomId
 * @param {dayjs.Dayjs} params.currentDate
 */
export function getDefaultValues({ initialRoomId, currentDate }) {
  /** @type {RoomsVisualizationFormValues} */
  const defaultValues = {
    roomId: initialRoomId,
    yearMonth: currentDate.startOf("month").format("YYYY-MM-DD"),
    adults: 1,
    children: 0,
    babies: 0,
    pets: 0,
  };
  return defaultValues;
}

/**
 * @typedef {object} Props
 * @property {number} initialRoomId
 * @property {Record<number, import("../../../types/Customer").CustomerRoomInfo>} roomsInfo
 * @property {boolean} canCreateBooking
 * @property {import("react").FC<{onSuccess: (clientId?: number) => void}> | null} ActionCreateCustomerComponent
 */
/**
 * @param {Props} props
 */
// #region component
export function RoomsVisualization({
  initialRoomId,
  roomsInfo,
  canCreateBooking,
  ActionCreateCustomerComponent,
}) {
  const intl = useIntl();

  const colorMode = useColorModeValue("light", "dark");

  const [bookingCreatePayload, setBookingCreatePayload] = useState(
    /** @type {object | null} */ (null),
  );

  const currentDate = useMemo(() => {
    return dayjs();
  }, []);

  const currentYear = useMemo(() => {
    return Number(currentDate.year());
  }, [currentDate]);

  const defaultValues = useMemo(() => {
    return getDefaultValues({
      initialRoomId,
      currentDate,
    });
  }, [currentDate, initialRoomId]);
  const form = useForm({
    defaultValues,
  });

  const { handleSubmit } = form;

  const [submittedValues, setSubmittedValues] = useState(defaultValues);

  // #region reset
  const reset = useCallback(() => {
    setMode("checkin");
    setCheckoutsData({});
    setStartDateSelection(null);
    setEndDateSelection(null);
    setSelectedDates([]);
  }, []);

  // #region onSubmit
  const onSubmit = useMemo(() => {
    return handleSubmit((values) => {
      // if adults, children or roomId have changed, reset the calendar to prevent date selection errors
      if (
        values.adults !== submittedValues.adults ||
        values.children !== submittedValues.children ||
        values.roomId !== submittedValues.roomId
      ) {
        reset();
      }
      setSubmittedValues(values);
    });
  }, [
    handleSubmit,
    reset,
    submittedValues.adults,
    submittedValues.children,
    submittedValues.roomId,
  ]);

  const isMounted = useRef(false);

  if (!isMounted.current) {
    onSubmit();
    isMounted.current = true;
  }

  const [havePricesRefreshed, setHavePricesRefreshed] = useState(false);
  const [arePricesRefreshing, setArePricesRefreshing] = useState(false);

  const startDateCalendar = useMemo(() => {
    return dayjs(submittedValues.yearMonth).startOf("month");
  }, [submittedValues.yearMonth]);

  const [visualizationData, setVisualizationData] = useState(
    /** @type {import("./types").VisualizationDataSet} */ ({}),
  );

  const [mode, setMode] = useState(
    /** @type {"checkin" | "checkout"} */ ("checkin"),
  );

  const [checkoutsData, setCheckoutsData] = useState(
    /** @type {import("./types").CheckoutsData} */ ({}),
  );

  const [startDateSelection, setStartDateSelection] = useState(
    /** @type {import("dayjs").Dayjs | null} */ (null),
  );
  const [endDateSelection, setEndDateSelection] = useState(
    /** @type {import("dayjs").Dayjs | null} */ (null),
  );
  const [selectedDates, setSelectedDates] = useState(
    /** @type {string[]} */ ([]),
  );

  const roomCapacity = useMemo(() => {
    const capacity = Object.values(roomsInfo).find(
      (roomInfo) => roomInfo.id === submittedValues.roomId,
    )?.capacity;
    return capacity;
  }, [roomsInfo, submittedValues.roomId]);

  const isMobile = useBreakpointValue({
    base: true,
    sm: false,
  });

  const monthsCount = useMemo(() => {
    return isMobile ? MONTHS_COUNT_MOBILE : MONTHS_COUNT_DESKTOP;
  }, [isMobile]);

  const [lastPriceCalculatedAt, setLastPriceCalculatedAt] = useState(
    /** @type {string | null} */ (null),
  );

  // #region escape
  useEffect(() => {
    // add escape key event listener
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        reset();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [reset]);

  // #region url
  const url = useMemo(() => {
    let fromDate = dayjs(submittedValues.yearMonth)
      .startOf("month")
      .startOf("day");
    if (fromDate.isBefore(dayjs().startOf("day"), "day")) {
      fromDate = dayjs().startOf("day");
    }
    return generateApiUrl({
      id: "@hotelsRoomsAvailabilitiesAction.byDates",
      parameters: {
        roomId: submittedValues.roomId,
      },
      query: {
        capacity: submittedValues.adults + submittedValues.children,
        workflow: "direct",
        from: fromDate.format("YYYY-MM-DD"),
        to: fromDate.add(monthsCount, "month").format("YYYY-MM-DD"),
        include_restricted: true,
        include_on_past: true,
        include_without_prices: true,
      },
    });
  }, [
    monthsCount,
    submittedValues.adults,
    submittedValues.children,
    submittedValues.roomId,
    submittedValues.yearMonth,
  ]);

  // #region useApi visualization
  /** @type {import("../../../hooks/useApi").UseApi<import("./types").VisualizationResponse>} */
  const { swrResponse: swrResponseVisualization } = useApi(
    submittedValues ? url : null,
    {
      swrConfig: {
        onSuccess: (response) => {
          setVisualizationData((currentVisualizationData) => {
            /** @type {import("./types").VisualizationDataSet} */
            const newVisualizationData = { ...currentVisualizationData };
            Object.keys(response.data).forEach((yearMonthDay) => {
              let dateOfPrevDay = dayjs(yearMonthDay).subtract(1, "day");
              /** @type {import("./types").VisualizationCell} */
              const dayData = {
                currentCell: response.data[yearMonthDay],
                prevCell:
                  getCell({
                    data: newVisualizationData,
                    yearMonth: dateOfPrevDay.format("YYYY-MM"),
                    day: dateOfPrevDay.format("DD"),
                  })?.currentCell ?? undefined,
              };
              const [year, month, day] = yearMonthDay.split("-");
              const yearMonth = `${year}-${month}`;

              newVisualizationData[yearMonth] = {
                ...(newVisualizationData[yearMonth] ?? {}),
                [day]: dayData,
              };
            });
            return newVisualizationData;
          });
        },
      },
    },
  );

  const onCloseBookingCreateModal = useCallback(() => {
    setBookingCreatePayload(null);
  }, []);

  // #region useApi status
  /** @type {import("../../../hooks/useApi").UseApi<{pricing_refresh_state: boolean, pricing_refreshed_at: string | null}>} */
  const { swrResponse: swrResponseStatus } = useApi(
    generateApiUrl({
      id: "@hotelsRoomsAction.status",
      parameters: {
        roomId: submittedValues?.roomId,
      },
    }),
    {
      swrConfig: {
        revalidateOnFocus: true,
        refreshInterval: 5000,
        onSuccess: (response) => {
          response.data.pricing_refreshed_at &&
            setLastPriceCalculatedAt((lastPriceCalculatedAt) => {
              // if the last price calculated date has changed, revalidate the visualization data
              if (
                lastPriceCalculatedAt !== response.data.pricing_refreshed_at
              ) {
                swrResponseVisualization.mutate();
              }
              return response.data.pricing_refreshed_at;
            });
          if (response.data.pricing_refresh_state) {
            setHavePricesRefreshed(true);
            setArePricesRefreshing(true);
          }
          if (!response.data.pricing_refresh_state && arePricesRefreshing) {
            setArePricesRefreshing(false);
            swrResponseVisualization.mutate();
          }
        },
      },
    },
  );

  // #region handleChange
  const handleChange = useCallback(
    /** @type {import("./Calendar").RoomsVisualizationCalendarOnChange} */
    ({ checkin, checkout, shouldClose }) => {
      if (!canCreateBooking) {
        return;
      }
      if (checkin === "" || checkout === "") {
        return;
      }
      setBookingCreatePayload({
        roomId: submittedValues.roomId,
        checkin: checkin.format("YYYY-MM-DD"),
        checkout: checkout.format("YYYY-MM-DD"),
        adults: submittedValues.adults,
        children: submittedValues.children,
        babies: submittedValues.babies,
        pets: submittedValues.pets,
      });
    },
    [
      canCreateBooking,
      submittedValues.adults,
      submittedValues.babies,
      submittedValues.children,
      submittedValues.pets,
      submittedValues.roomId,
    ],
  );

  // #region return
  return (
    <>
      <Stack spacing="16px" my="16px">
        <Box>
          <Text>
            <FormattedMessage defaultMessage="Visualisez les tarifs de séjours possibles en survolant le calendrier à partir du jour de départ sélectionné." />
          </Text>
          <Text>
            <FormattedMessage defaultMessage="Il est possible de créer des séjours de 84 nuitées maximum. À partir de la 35e nuitée, le tarif se calcule par multiple de 7 par semaine." />
          </Text>
        </Box>
        <FormProvider {...form}>
          <FormAutoSubmit onSubmit={onSubmit} />
          <form onSubmit={onSubmit}>
            <Filters
              form={form}
              roomsInfo={roomsInfo}
              currentYear={currentYear}
              setWasPricingRefreshing={setHavePricesRefreshed}
              maxCapacity={roomCapacity}
            />
          </form>
        </FormProvider>
        <DataHandler
          swrResponse={swrResponseStatus}
          keepDataOnRevalidation={true}>
          {({ data: { pricing_refresh_state: pricingRefreshState } }) => (
            <Box>
              <Collapse in={havePricesRefreshed}>
                <Box pb="16px">
                  <Alert status={pricingRefreshState ? "warning" : "success"}>
                    <HStack>
                      {pricingRefreshState && <Spinner size="sm" />}
                      <AlertDescription>
                        {pricingRefreshState ? (
                          <FormattedMessage defaultMessage="Tarifs en cours de calcul..." />
                        ) : (
                          <FormattedMessage defaultMessage="Les tarifs ont été mis à jour." />
                        )}
                      </AlertDescription>
                    </HStack>
                  </Alert>
                </Box>
              </Collapse>

              <RoomsVisualizationCalendar
                startDateCalendar={startDateCalendar}
                monthsCount={monthsCount}
                visualizationData={visualizationData}
                mode={mode}
                checkoutsData={checkoutsData}
                startDateSelection={startDateSelection}
                endDateSelection={endDateSelection}
                selectedDates={selectedDates}
                setEndDateSelection={setEndDateSelection}
                setSelectedDates={setSelectedDates}
                setStartDateSelection={setStartDateSelection}
                reset={reset}
                setCheckoutsData={setCheckoutsData}
                setMode={setMode}
                onChange={handleChange}
                withPrices={true}
                isValidating={swrResponseVisualization.isValidating}
                preventPast={false}
              />
            </Box>
          )}
        </DataHandler>
        <RGrid minCellWidth="500px">
          <Text
            color={colorMode === "light" ? "gray.800" : "white"}
            backgroundColor={
              colorMode === "light" ? "brandPrimary.100" : "brandPrimary.700"
            }
            borderRadius="12px"
            p="8px 16px">
            <FormattedMessage defaultMessage="Veuillez sélectionner un jour d'arrivée dans le calendrier pour calculer les tarifs des séjours possibles. Visualisez ensuite les tarifs de séjour possibles en survolant le calendrier à partir de cette date." />
          </Text>
          <DataHandler
            swrResponse={swrResponseStatus}
            keepDataOnRevalidation={true}
            loading={<Skeleton borderRadius="12px" minH="64px" />}>
            {() => (
              <>
                {lastPriceCalculatedAt !== null && (
                  <Text
                    color={colorMode === "light" ? "gray.800" : "white"}
                    backgroundColor={
                      colorMode === "light"
                        ? "brandSecondary.100"
                        : "brandSecondary.700"
                    }
                    borderRadius="12px"
                    p="8px 16px">
                    <FormattedMessage
                      defaultMessage="Date de dernière modifications des tarifs: {date}"
                      values={{
                        date: intl.formatDate(lastPriceCalculatedAt, {
                          year: "numeric",
                          month: "long",
                          day: "numeric",
                          hour: "numeric",
                          minute: "numeric",
                        }),
                      }}
                    />
                  </Text>
                )}
              </>
            )}
          </DataHandler>
        </RGrid>
      </Stack>

      <BookingCreateModal
        isOpen={bookingCreatePayload !== null}
        onClose={onCloseBookingCreateModal}
        checkin={bookingCreatePayload?.checkin}
        checkout={bookingCreatePayload?.checkout}
        adults={bookingCreatePayload?.adults}
        babies={bookingCreatePayload?.babies}
        children={bookingCreatePayload?.children}
        pets={bookingCreatePayload?.pets}
        roomId={bookingCreatePayload?.roomId}
        ActionCreateCustomerComponent={ActionCreateCustomerComponent}
      />
    </>
  );
}
