/**
 * @param {object} param0
 * @param {import("../../../types/CartBookingEstimation").BookingEstimation | import("../../../types/CartBooking").CartBooking | null} param0.data
 * @param {import("../../../types/Cart").CartCreateOrUpdateBooking} param0.bookingFormValues
 * @returns {import("../../../types/Cart").BookingSummary}
 */
export default function getBookingSummary({ data, bookingFormValues }) {
  const discountTotal = (bookingFormValues?.discounts ?? [])?.reduce(
    (acc, discount) => Number(acc) + (discount?.amount ?? 0),
    0,
  );

  const serviceTotalRaw = (bookingFormValues?.services ?? [])?.reduce(
    (acc, service) =>
      acc + (service.unitary_amount || 0) * (service.quantity || 0),
    0,
  );

  const serviceTotalAmountDiscount = (
    bookingFormValues?.services ?? []
  )?.reduce(
    (acc, service) => acc + (service.discount || 0) * (service.quantity || 0),
    0,
  );

  const serviceTotalAmount = (bookingFormValues?.services ?? [])?.reduce(
    (acc, service) => acc + (service.total || 0),
    0,
  );

  const tripAmount =
    (bookingFormValues?.periods_amount || 0) -
    discountTotal +
    serviceTotalAmount;

  return {
    periodsAmountRaw: bookingFormValues?.periods_amount || 0,
    periodsAmountDiscount: discountTotal,
    periodsAmount: (bookingFormValues?.periods_amount || 0) - discountTotal,
    servicesAmountRaw: serviceTotalRaw,
    servicesAmount: serviceTotalAmount,
    servicesAmountDiscount: serviceTotalAmountDiscount,
    total: tripAmount,
    touristTaxAmount: data?.tourist_tax_amount ?? null,
    uuid: bookingFormValues.uuid,
  };
}
