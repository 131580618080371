import {
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { ApiErrorProvider } from "../../../components/ReactHookForm/ApiError";
import { Handler } from "./Handler";

/**
 * @typedef Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {(cartId: number, bookingId: number) => void} [onSuccess]
 * @property {number} [roomId]
 * @property {string} [checkin]
 * @property {string} [checkout]
 * @property {number} [adults]
 * @property {number} [babies]
 * @property {number} [children]
 * @property {number} [pets]
 * @property {import("react").FC<{onSuccess: (clientId?: number) => void}> | null} ActionCreateCustomerComponent
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export function BookingCreateModal({
  isOpen,
  onClose,
  onSuccess,
  roomId,
  checkin,
  checkout,
  adults,
  babies,
  children,
  pets,
  ActionCreateCustomerComponent,
}) {
  const intl = useIntl();

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      blockScrollOnMount
      size="6xl">
      <ModalOverlay />

      <ModalContent>
        <ModalCloseButton />

        <ModalHeader>
          {intl.formatMessage({
            defaultMessage: "Créer une réservation",
          })}
        </ModalHeader>

        <ApiErrorProvider>
          <Handler
            onClose={onClose}
            onSuccess={onSuccess}
            roomId={roomId}
            checkin={checkin}
            checkout={checkout}
            adults={adults}
            babies={babies}
            children={children}
            pets={pets}
            ActionCreateCustomerComponent={ActionCreateCustomerComponent}
          />
        </ApiErrorProvider>
      </ModalContent>
    </Modal>
  );
}
