import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
} from "@chakra-ui/react";
import FormControlRHF from "../../../../../../components/ReactHookForm/FormControlRHF";
import { FormProvider, useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {(discount) => void} onSuccess
 * @property {number} [minQuantity]
 * @property {number} [maxQuantity]
 * @property {import("../../../../../../types/Cart").CartCreateBookingDiscount} [initialDiscount]
 */

/**
 * @param {Props} props
 */
export function CreateOrUpdateBookingDiscountModal({
  isOpen,
  onClose,
  onSuccess,
  initialDiscount,
}) {
  const intl = useIntl();

  const form = useForm({
    defaultValues: /**
      @type {{ name: string, amount: number }} 
    */ ({
      name: initialDiscount?.name ?? "",
      amount: initialDiscount?.amount ?? "",
    }),
  });
  const { handleSubmit, control } = form;

  function onSubmit(values) {
    onSuccess(values);
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onCloseComplete={() => form.reset()}
      onClose={onClose}
      scrollBehavior="inside"
      size="2xl"
      blockScrollOnMount={false}>
      <ModalOverlay />

      <ModalContent>
        <FormProvider {...form}>
          <ModalCloseButton />

          <ModalHeader>
            {initialDiscount ? (
              <FormattedMessage defaultMessage="Modifier une promotion" />
            ) : (
              <FormattedMessage defaultMessage="Ajouter une promotion" />
            )}
          </ModalHeader>

          <ModalBody>
            <form
              id="createDiscount"
              onSubmit={(e) => {
                // Prevent the submission of nested form like in hotel payment mode form
                e.preventDefault();
                handleSubmit(onSubmit)();
                e.stopPropagation();
              }}
              autoComplete="off"
              noValidate>
              <Stack spacing="1rem">
                <FormControlRHF
                  control={control}
                  label={intl.formatMessage({
                    defaultMessage: "Nom de la promotion",
                  })}
                  rules={{
                    required: true,
                  }}
                  name="name"
                />

                <FormControlRHF
                  control={control}
                  type="number-input"
                  label={intl.formatMessage({
                    defaultMessage: "Montant unitaire TTC",
                  })}
                  rules={{
                    required: true,
                    min: 0,
                  }}
                  name="amount"
                />
              </Stack>
            </form>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup size="sm">
              <Button>
                <FormattedMessage defaultMessage="Fermer" />
              </Button>

              <Button
                type="submit"
                form="createDiscount"
                colorScheme="brandPrimary">
                {initialDiscount ? (
                  <FormattedMessage defaultMessage="Modifier" />
                ) : (
                  <FormattedMessage defaultMessage="Ajouter" />
                )}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
}
