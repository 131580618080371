import {
  Alert,
  AlertDescription,
  ListItem,
  UnorderedList,
  Text,
} from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {string | null} apiErrorDetail
 * @property {string[]} warnings
 * @property {boolean} isEstimateLoading
 */

/**
 * @param {Props} props
 */
export function BookingWarningAlert({
  apiErrorDetail,
  warnings,
  isEstimateLoading,
}) {
  return (
    <>
      {(apiErrorDetail || warnings.length > 0) && !isEstimateLoading ? (
        <Alert status="warning">
          <AlertDescription>
            {apiErrorDetail ? (
              <Text>{apiErrorDetail}</Text>
            ) : warnings ? (
              warnings.map((warning, i) => (
                <UnorderedList key={i}>
                  <ListItem>{warning}</ListItem>
                </UnorderedList>
              ))
            ) : null}
          </AlertDescription>
        </Alert>
      ) : null}
    </>
  );
}
