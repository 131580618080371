import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "../../Icon";
import { Body } from "./Body";

/**
 * @typedef {object} Props
 * @property {number | null} bookingId
 * @property {number} roomId
 * @property {string} checkin
 * @property {string} checkout
 * @property {number} capacity the desired capacity (adults + children)
 * @property {"direct" | "system"} [workflow]
 * @property {boolean} includeRestricted si `true`, considère les séjours avec des contraintes disponibles
 * @property {boolean} includeOnPast si `true`, renvoi les séjours dans le passé
 * @property {boolean} includeWithoutPrices si `true`, considère les séjours sans tarifs disponibles
 * @property {(params: { checkin: string, checkout: string }) => void} onChange a memoized callback to update the checkin and checkout dates
 */
/**
 * @param {Props} props
 */
export function CheckinCheckoutInput({
  bookingId,
  roomId,
  checkin,
  checkout,
  capacity,
  workflow,
  includeRestricted,
  includeOnPast,
  includeWithoutPrices,
  onChange,
}) {
  const intl = useIntl();

  return (
    <Popover isLazy={true} lazyBehavior="unmount">
      {({ onClose }) => (
        <>
          <PopoverTrigger>
            <Button
              flexGrow={1}
              variant="outline"
              fontSize="1rem"
              fontWeight="normal"
              minW="260px"
              textAlign="left"
              leftIcon={
                <Icon icon="ms_calendar_month" size="24px" color="gray.500" />
              }>
              <Text as="span" flexGrow={1}>
                {checkin !== "" && checkout !== "" ? (
                  <FormattedMessage
                    defaultMessage="Du {checkin} au {checkout}"
                    values={{
                      checkin: intl.formatDate(checkin, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      }),
                      checkout: intl.formatDate(checkout, {
                        year: "numeric",
                        month: "numeric",
                        day: "numeric",
                      }),
                    }}
                  />
                ) : (
                  <FormattedMessage defaultMessage="Choisir les dates" />
                )}
              </Text>
            </Button>
          </PopoverTrigger>

          <PopoverContent w="auto" borderRadius="10px">
            <PopoverArrow />

            <PopoverBody maxW="100dvw" overflowX="auto">
              <Body
                roomId={roomId}
                bookingId={bookingId}
                capacity={capacity}
                onClose={onClose}
                workflow={workflow}
                includeRestricted={includeRestricted}
                includeOnPast={includeOnPast}
                includeWithoutPrices={includeWithoutPrices}
                onChange={onChange}
                checkin={checkin}
                checkout={checkout}
              />
            </PopoverBody>
          </PopoverContent>
        </>
      )}
    </Popover>
  );
}
