import { Box, Button, HStack, Icon, Stack, Text } from "@chakra-ui/react";
import { forwardRef } from "react";
import { MdCheck } from "react-icons/md";

export const Option = forwardRef(
  /**
   * @param {import("../Autocomplete").AutocompleteRenderOptionParams} props
   */
  function Option(
    { value, item, itemValue, name, toggleValue, focusPrev, focusNext },
    ref,
  ) {
    function getAddress() {
      let address = "";

      if (item?.["address"]) {
        address = item["address"];
      }

      if (item?.["address1"]) {
        address += item["address1"];
      }

      if (item?.["address2"]) {
        address += `, ${item["address2"]}`;
      }

      if (item?.["zipcode"]) {
        address += ` - ${item["zipcode"]}`;
      }

      if (item?.["city"]) {
        address += ` ${item["city"]}`;
      }

      return address;
    }

    return (
      <Box mt=".5rem" _first={{ mt: 0 }}>
        <Button
          ref={ref}
          onClick={() => {
            toggleValue({ value: itemValue, item });
          }}
          onKeyDown={(event) => {
            if (event.key === "ArrowUp") {
              event.preventDefault();
              focusPrev();
            }
            if (event.key === "ArrowDown") {
              event.preventDefault();
              focusNext();
            }
          }}
          alignItems="center"
          justifyContent="flex-start"
          variant="ghost"
          size="sm"
          pl="1rem"
          pr="1rem"
          w="100%"
          textAlign="left"
          height="auto">
          <Stack maxW="full" spacing="0">
            <HStack>
              <Text
                as="span"
                flexGrow={1}
                overflow="hidden"
                textOverflow="ellipsis"
                whiteSpace="nowrap">
                {`${item?.["lastname"]} ${item?.["firstname"]}`}
              </Text>
            </HStack>

            <Text
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              fontWeight="normal">
              {getAddress() ?? itemValue}
            </Text>
          </Stack>

          {(value === itemValue ||
            (Array.isArray(value) && value?.includes?.(itemValue))) && (
            <HStack pl=".5rem">
              <Icon as={MdCheck} />
            </HStack>
          )}
        </Button>
      </Box>
    );
  },
);
