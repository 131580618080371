import { Box, Grid, HStack, IconButton, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { memo, useMemo } from "react";
import { FormattedDate, useIntl } from "react-intl";
import { Icon } from "../../../components/Icon";
import { CELL_WIDTH } from "./Month";

export const MonthHeader = memo(
  /**
   * @typedef {object} Props
   * @property {string} startDateMonthIso
   * @property {() => void} [onClickPrev]
   * @property {() => void} [onClickNext]
   * @property {number} monthIndex
   * @property {number} monthsCount
   * @property {boolean} preventPast prevent user from going into the past by disabling prev month button
   */
  /**
   * @param {Props} props
   */
  function MonthHeader({
    startDateMonthIso,
    onClickPrev,
    onClickNext,
    monthIndex,
    monthsCount,
    preventPast,
  }) {
    const intl = useIntl();

    const isDisabled = useMemo(() => {
      if (preventPast === false) {
        return false;
      }
      const prevMonth = dayjs(startDateMonthIso).subtract(1, "month");
      return prevMonth.isBefore(dayjs(), "month");
    }, [preventPast, startDateMonthIso]);

    return (
      <Stack spacing="8px">
        <Grid gridTemplateColumns="1fr auto 1fr" alignItems="center">
          {onClickPrev !== undefined && monthIndex === 0 && (
            <Box gridColumn={1}>
              <IconButton
                icon={<Icon icon="ms_chevron_left" />}
                aria-label={intl.formatMessage({
                  defaultMessage: "Mois précédent",
                })}
                onClick={onClickPrev}
                isDisabled={isDisabled}
              />
            </Box>
          )}

          <Box gridColumn={2}>
            <Text textTransform="capitalize" mx="auto">
              <FormattedDate
                value={startDateMonthIso}
                month="long"
                year="numeric"
              />
            </Text>
          </Box>

          {onClickNext !== undefined && monthIndex === monthsCount - 1 && (
            <Box justifySelf="flex-end" gridColumn={3}>
              <IconButton
                icon={<Icon icon="ms_chevron_right" />}
                aria-label={intl.formatMessage({
                  defaultMessage: "Mois suivant",
                })}
                onClick={onClickNext}
              />
            </Box>
          )}
        </Grid>
        <HStack spacing="0">
          {Array.from({ length: 7 }, (_, index) => {
            return (
              <Box key={index} w={CELL_WIDTH}>
                <Text
                  textAlign="center"
                  fontSize="1rem"
                  lineHeight="1rem"
                  color={index >= 5 ? "brandSecondary.600" : undefined}>
                  <FormattedDate
                    value={dayjs()
                      .day(index + (1 % 7))
                      .toISOString()}
                    weekday="narrow"
                  />
                </Text>
              </Box>
            );
          })}
        </HStack>
      </Stack>
    );
  },
);
