import { Box, HStack, useColorModeValue } from "@chakra-ui/react";
import { useCallback } from "react";
import { typedForwardRef } from "../../../hocs/typedForwardRef";
import { typedMemo } from "../../../hocs/typedMemo";
import { Icon } from "../../Icon";

export const Option = typedMemo(
  typedForwardRef(
    /**
     * @template TValue, TData
     * @typedef {object} Props
     * @property {import("./Select").SelectOption<TValue, TData>} option
     * @property {boolean} isChecked
     * @property {(event: { target: { name: string, value: TValue | "" } }) => void} onChange
     * @property {(data: TData) => JSX.Element} [renderOption]
     * @property {() => void} onClick
     */
    /**
     * @template TValue, TData
     * @param {Props<TValue, TData>} props
     */
    function Option(
      { option, isChecked, onChange, renderOption, onClick },
      ref,
    ) {
      const handleClick = useCallback(() => {
        onClick();
        onChange({
          target: {
            name: "rooms",
            value: option.value,
          },
        });
      }, [onChange, onClick, option.value]);

      const colorMode = useColorModeValue("light", "dark");

      return (
        <HStack
          ref={ref}
          as="button"
          type="button"
          textAlign="left"
          onClick={handleClick}
          maxW="100%"
          overflow="hidden"
          flexShrink={0}
          backgroundColor={
            isChecked
              ? colorMode === "light"
                ? "brandPrimary.100"
                : "brandPrimary.700"
              : undefined
          }
          _hover={{
            backgroundColor:
              colorMode === "light" ? "brandPrimary.200" : "brandPrimary.600",
          }}
          w="full"
          px="16px">
          {isChecked && (
            <Box flexShrink={0}>
              <Icon icon="ms_check" />
            </Box>
          )}
          <Box pl={isChecked ? undefined : "24px"}>
            {renderOption?.(option.data) ?? <Box py="4px">{option.label}</Box>}
          </Box>
        </HStack>
      );
    },
  ),
);
