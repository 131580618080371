import {
  Box,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import FormControlRHF from "../../../../../components/ReactHookForm/FormControlRHF";
import { BOOKINGS_WORKFLOW_VALUE_DIRECT } from "../../../../../constants/bookings";
import { CURRENCY_OPTIONS } from "../../../../../constants/intl";
import {
  roomsServicesStrategyMessage,
  ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY,
} from "../../../../../constants/roomsServices";
import formatAmountByAmountMode from "../../../../../helpers/rooms/services/formatAmountByAmountMode";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { NumberIncrementer } from "../../../../../components/Form/NumberIncrementer";
import { Icon } from "../../../../../components/Icon";
import { CreateOrUpdateBookingServiceModal } from "./CreateOrUpdateBookingServiceModal";
/**
 * @typedef {object} Props
 * @property {import("../../../../../types/Cart").CartCreateBookingService} service
 * @property {number} bookingIndex
 * @property {number} serviceIndex
 * @property {import("react-hook-form").UseFieldArrayRemove} serviceRemove
 * @property {import("react-hook-form").UseFieldArrayUpdate<import("../../../../../types/Cart").CartCreateDataFormValues, any>} [serviceUpdate]
 * @property {keyof typeof import("../../../../../constants/carts").CARTS_WORKFLOWS} workflow
 * @property {(data?: import("../useBookingEstimate").RequestEstimateParams) => void} requestEstimate
 */

/**
 * @param {Props} props
 */
export function BookingService({
  service,
  bookingIndex,
  serviceIndex,
  serviceRemove,
  serviceUpdate,
  workflow,
  requestEstimate,
}) {
  const intl = useIntl();

  const { control, getValues } = useFormContext();

  const { isOpen, onOpen, onClose } = useDisclosure();

  function onModalSuccess(service) {
    const services = getValues(`data.bookings.${bookingIndex}.services`);

    const serviceIndex = services.findIndex((s) => s.uuid === service.uuid);

    const foundService = services[serviceIndex];

    if (foundService) {
      serviceUpdate?.(serviceIndex, { ...foundService, ...service });
      requestEstimate();
    }
  }

  function onCheckboxChange(e) {
    const isChecked = e.target.checked;

    if (!isChecked) {
      serviceRemove(serviceIndex);
      requestEstimate();
    }
  }

  return (
    <>
      <Box py=".5rem">
        <Flex
          flexDir={{ base: "column", md: "row" }}
          gap={{ base: "1rem", md: "0" }}
          justifyContent="space-between"
          background="gray.100"
          p=".75rem"
          borderRadius="8px">
          <Flex flexDir="column" maxWidth="80%">
            <HStack spacing="1rem" alignItems="center">
              <Box pt=".5rem">
                <Checkbox
                  backgroundColor="white"
                  isChecked={true}
                  onChange={onCheckboxChange}
                />
              </Box>

              <Text variant="h5" fontWeight="500">
                {service.name}
              </Text>

              {service?.amount && service.amount_mode && (
                <>
                  {service.strategy ? (
                    <Flex alignItems="center" gap=".25rem">
                      {intl.formatMessage(
                        {
                          defaultMessage:
                            "{amountForFreeLabel, select, 0 {Gratuit} other {{amount} / {labelStrategy} }}",
                        },
                        {
                          amountForFreeLabel: service?.amount,
                          amount: (
                            <Text variant="text-sm">
                              {formatAmountByAmountMode({
                                service: {
                                  amount: service?.amount,
                                  amount_mode: service.amount_mode,
                                },
                                intl,
                              })}
                            </Text>
                          ),
                          labelStrategy: (
                            <Text variant="text-xs" color="gray.600">
                              {intl.formatMessage(
                                roomsServicesStrategyMessage,
                                {
                                  strategy: service.strategy
                                    ? service.strategy
                                    : "unknown",
                                },
                              )}
                            </Text>
                          ),
                        },
                      )}
                    </Flex>
                  ) : (
                    <Text variant="text-sm">
                      {formatAmountByAmountMode({
                        service: {
                          amount: service.amount,
                          amount_mode: service.amount_mode,
                        },
                        intl,
                      })}
                    </Text>
                  )}
                </>
              )}
            </HStack>

            <Text isTruncated={true} maxWidth="100%">
              {service.about}
            </Text>
          </Flex>

          <Flex gap="1rem" alignItems="center">
            {service.strategy ===
              ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY && (
              <FormControlRHF
                name={`data.bookings.${bookingIndex}.services.${serviceIndex}.quantity`}
                rules={{
                  onChange: () => requestEstimate(),
                }}
                control={control}
                render={(field) => (
                  <NumberIncrementer
                    min={
                      service.min_quantity !== ""
                        ? service.min_quantity
                        : undefined
                    }
                    max={
                      service.max_quantity !== ""
                        ? service.max_quantity
                        : undefined
                    }
                    {...field}
                  />
                )}
              />
            )}

            <Text>
              {intl.formatNumber(service?.total || 0, CURRENCY_OPTIONS)}
            </Text>

            {!service.is_required &&
              workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT && (
                <IconButton
                  icon={<Icon icon="ms_delete" size="1.25rem" />}
                  variant="outline"
                  size="sm"
                  colorScheme="red"
                  onClick={() => serviceRemove(serviceIndex)}
                  aria-label={intl.formatMessage({
                    defaultMessage: "Supprimer",
                  })}
                />
              )}

            {workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT && serviceUpdate && (
              <IconButton
                icon={<Icon icon="ms_edit" size="1.25rem" />}
                variant="outline"
                size="sm"
                onClick={onOpen}
                aria-label={intl.formatMessage({
                  defaultMessage: "Modifier",
                })}
              />
            )}
          </Flex>
        </Flex>
      </Box>

      <CreateOrUpdateBookingServiceModal
        key={service.uuid}
        isOpen={isOpen}
        onClose={onClose}
        initialService={service}
        onSuccess={onModalSuccess}
        workflow={workflow}
      />
    </>
  );
}
