import { Box, Checkbox, Flex, HStack, Text } from "@chakra-ui/react";
import { roomsServicesStrategyMessage } from "../../../../../constants/roomsServices";
import formatAmountByAmountMode from "../../../../../helpers/rooms/services/formatAmountByAmountMode";
import { useIntl } from "react-intl";
/**
 * @typedef {object} Props
 * @property {import("../../../../../types/Cart").CartCreateBookingService} availableService
 * @property {import("react-hook-form").UseFieldArrayAppend<import("../../../../../types/Cart").CartCreateDataFormValues, any>} serviceAppend
 * @property {(data?: import("../useBookingEstimate").RequestEstimateParams) => void} requestEstimate
 */

/**
 * @param {Props} props
 */
export function BookingAvailableService({
  availableService,
  serviceAppend,
  requestEstimate,
}) {
  const intl = useIntl();

  function onCheckboxChange(e) {
    const isChecked = e.target.checked;

    if (isChecked) {
      serviceAppend({
        service_id: availableService.service_id,
        min_quantity: availableService.min_quantity,
        max_quantity: availableService.max_quantity,
        quantity: availableService.min_quantity,
        strategy: availableService.strategy,
        amount_mode: availableService.amount_mode,
        is_required: availableService.is_required,
        about: availableService.about,
        total: availableService.total,
        name: availableService.name,
        amount: availableService.amount,
        unitary_amount: availableService.unitary_amount,
        discount: availableService.discount,
        uuid: availableService.uuid,
      });

      requestEstimate();
    }
  }

  return (
    <>
      <Box py=".5rem">
        <Flex
          justifyContent="space-between"
          background="gray.100"
          p=".75rem"
          borderRadius="8px">
          <Flex flexDir="column" maxWidth="80%">
            <HStack spacing="1rem" alignItems="center">
              <Box pt=".5rem">
                <Checkbox backgroundColor="white" onChange={onCheckboxChange} />
              </Box>

              <Text variant="h5" fontWeight="500">
                {availableService.name}
              </Text>

              {availableService?.amount && availableService.amount_mode && (
                <>
                  {availableService.strategy ? (
                    <Flex alignItems="center" gap=".25rem">
                      {intl.formatMessage(
                        {
                          defaultMessage:
                            "{amountForFreeLabel, select, 0 {Gratuit} other {{amount} / {labelStrategy} }}",
                        },
                        {
                          amountForFreeLabel: availableService?.amount,
                          amount: (
                            <Text variant="text-sm">
                              {formatAmountByAmountMode({
                                service: {
                                  amount: availableService?.amount,
                                  amount_mode: availableService.amount_mode,
                                },
                                intl,
                              })}
                            </Text>
                          ),
                          labelStrategy: (
                            <Text variant="text-xs" color="gray.600">
                              {intl.formatMessage(
                                roomsServicesStrategyMessage,
                                {
                                  strategy: availableService.strategy
                                    ? availableService.strategy
                                    : "unknown",
                                },
                              )}
                            </Text>
                          ),
                        },
                      )}
                    </Flex>
                  ) : (
                    <Text variant="text-sm">
                      {formatAmountByAmountMode({
                        service: {
                          amount: availableService.amount,
                          amount_mode: availableService.amount_mode,
                        },
                        intl,
                      })}
                    </Text>
                  )}
                </>
              )}
            </HStack>

            <Text isTruncated={true} maxWidth="100%">
              {availableService.about}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </>
  );
}
