import { POLICIES_POLICY_VALUE_CREATE_BOOKINGS } from "../../../constants/policies";
import useCanPerform from "../../../hooks/useCanPerform";

/**
 * @param {object} params
 * @param {import("../../../types/Customer").CustomerRoomInfo} params.resource
 * @param {(params: { policies: string; resourcePolicies: string[] | undefined }) => boolean} params.canPerform
 */

export function canPerformRoomInfoCreateBooking({ resource, canPerform }) {
  const resourcePolicies = resource?.meta?.policies;

  return canPerform({
    policies: POLICIES_POLICY_VALUE_CREATE_BOOKINGS,
    resourcePolicies: resourcePolicies,
  });
}

/**
 * @typedef {object} Props
 * @property {import("../../../types/Customer").CustomerRoomInfo} resource
 * @property {(params: { isAllowed: boolean }) => import("react").ReactElement} children
 */

/**
 * @param {Props} props
 */
export function CanPerformRoomInfoCreateBooking({ children, resource }) {
  const canPerform = useCanPerform();

  const isAllowed = canPerformRoomInfoCreateBooking({
    resource,
    canPerform,
  });

  return (
    <>
      {children({
        isAllowed,
      })}
    </>
  );
}
