import { memo } from "react";

/**
 * A typed version of React.memo.
 * @template T
 * @param {T} component The component to memoize.
 * @returns {T} The memoized component.
 */
// @ts-ignore
// this is an enforced type that is not supported by the current version of the library
export const typedMemo = (component) => memo(component);
