import { Button, Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { BOOKINGS_WORKFLOW_VALUE_DIRECT } from "../../../../../constants/bookings";
import { ROOMS_SERVICES_AMOUNT_MODE_VALUE_EURO } from "../../../../../constants/roomsServices";
import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Icon } from "../../../../../components/Icon";
import { BookingAvailableService } from "../availableService/BookingAvailableService";
import { BookingService } from "./BookingService";
import { CreateOrUpdateBookingServiceModal } from "./CreateOrUpdateBookingServiceModal";

/**
 * @typedef {object} Props
 * @property {keyof typeof import("../../../../../constants/carts").CARTS_WORKFLOWS} workflow
 * @property {number} bookingIndex
 * @property {boolean} displayAddButton
 * @property {boolean} isLoading
 * @property {import("../../../../../types/Cart").CartCreateBookingService[]} availableServices
 * @property {import("react-hook-form").FieldArrayWithId<import("../../../../../types/Cart").CartCreateDataFormValues, "data.bookings.0.services", "id">[]} services
 * @property {import("react-hook-form").UseFieldArrayAppend<import("../../../../../types/Cart").CartCreateDataFormValues, "data.bookings.0.services">} servicesAppend
 * @property {import("react-hook-form").UseFieldArrayRemove} servicesRemove
 * @property {import("react-hook-form").UseFieldArrayUpdate<import("../../../../../types/Cart").CartCreateDataFormValues, "data.bookings.0.services">} servicesUpdate
 * @property {(data?: import("../useBookingEstimate").RequestEstimateParams) => void} requestEstimate
 */

/**
 * @param {Props} props
 */
export function BookingServices({
  workflow,
  bookingIndex,
  displayAddButton,
  isLoading,
  availableServices,
  services,
  servicesAppend,
  servicesRemove,
  servicesUpdate,
  requestEstimate,
}) {
  // Filter the available services to remove the services already added in the services array
  const filteredAvailableServices = useMemo(
    () =>
      availableServices.filter(
        (availableService) =>
          !services.some(
            (service) => service.service_id === availableService.service_id,
          ),
      ),
    [availableServices, services],
  );

  // Merge the services and the filtered available services together and sort them by name to display them in the same list
  const allServices = [...services, ...filteredAvailableServices].sort((a, b) =>
    a.name > b.name ? 1 : b.name > a.name ? -1 : 0,
  );

  const {
    isOpen: isOpenCreateModal,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();

  const intl = useIntl();

  function onModalSuccess(service) {
    servicesAppend({
      service_id: "",
      min_quantity: "",
      max_quantity: "",
      strategy: "",
      amount_mode: ROOMS_SERVICES_AMOUNT_MODE_VALUE_EURO,
      is_required: false,
      about: "",
      ...service,
    });

    requestEstimate();
  }

  return (
    <>
      <Stack spacing="1rem">
        {services.length > 0 && (
          <Text variant="h5" color="gray.500">
            {intl.formatMessage({ defaultMessage: "Suppléments disponibles" })}
          </Text>
        )}

        <Flex
          flexDir="column"
          gap=".25rem"
          opacity={isLoading ? ".5" : "1"}
          pointerEvents={isLoading ? "none" : undefined}>
          {allServices.map((service) => {
            // If the service has a uuid, it means its a service, not an available service
            if (service.uuid) {
              return (
                <BookingService
                  key={service.uuid}
                  service={service}
                  serviceUpdate={servicesUpdate}
                  serviceRemove={servicesRemove}
                  bookingIndex={bookingIndex}
                  // @ts-ignore
                  // We use the services array here since the service index is used to remove the service when unchecking the checkbox (so allServices indexes don't match the services indexes)
                  serviceIndex={services.indexOf(service)}
                  workflow={workflow}
                  requestEstimate={requestEstimate}
                />
              );
            } else {
              return (
                <BookingAvailableService
                  key={service.service_id}
                  availableService={service}
                  serviceAppend={servicesAppend}
                  requestEstimate={requestEstimate}
                />
              );
            }
          })}
        </Flex>

        {workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT && displayAddButton && (
          <Flex justifyContent="flex-end">
            <Button
              maxWidth="14rem"
              variant="outline"
              size="sm"
              onClick={onOpenCreateModal}
              leftIcon={<Icon icon="ms_add" />}>
              {intl.formatMessage({
                defaultMessage: "Ajouter un supplément",
              })}
            </Button>
          </Flex>
        )}

        <CreateOrUpdateBookingServiceModal
          isOpen={isOpenCreateModal}
          onClose={onCloseCreateModal}
          onSuccess={onModalSuccess}
          workflow={workflow}
        />
      </Stack>
    </>
  );
}
