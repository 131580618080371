import { forwardRef } from "react";

/**
 * @template T
 * @template [P={}]
 * @param {(props: P, ref: React.Ref<T>) => JSX.Element} render
 * @returns {(props: P & React.RefAttributes<T>) => JSX.Element}
 */
export function typedForwardRef(render) {
  return /** @type {(props: P & React.RefAttributes<T>) => JSX.Element} */ (
    // @ts-ignore
    forwardRef(render)
  );
}
