import { useEffect, useState } from "react";
import { Option } from "./Option";

/**
 * @template TValue, TData
 * @typedef {object} Props
 * @property {import("./Select").SelectOption<TValue, TData>[]} options
 * @property {TValue} value
 * @property {(event: { target: { name: string, value: TValue | "" } }) => void} onChange
 * @property {(data: TData) => JSX.Element} [renderOption]
 * @property {import("./Select").SelectOption<TValue, TData> | undefined} selectedOption
 * @property {React.MutableRefObject<HTMLDivElement[]>} optionsRef
 * @property {() => void} onClose
 * @property {boolean} isMobile
 * @property {React.MutableRefObject<HTMLDivElement | null>} containerRef
 */
/**
 * @template TValue, TData
 * @param {Props<TValue, TData>} props
 */
export function Body({
  options,
  value,
  onChange,
  renderOption,
  selectedOption,
  optionsRef,
  onClose,
  isMobile,
  containerRef,
}) {
  const [initialSelectedOption] = useState(selectedOption);

  useEffect(() => {
    if (initialSelectedOption && !isMobile) {
      const index = options.findIndex(
        (option) => option.value === initialSelectedOption.value,
      );
      if (
        index !== -1 &&
        containerRef.current !== null &&
        optionsRef.current[index] !== null
      ) {
        const container = containerRef.current;
        const element = optionsRef.current[index];

        if (element) {
          const containerRect = container.getBoundingClientRect();
          const elementRect = element.getBoundingClientRect();
          const offsetTop = elementRect.top - containerRect.top;

          const scrollTop =
            container.scrollTop +
            offsetTop -
            container.clientHeight / 2 +
            element.clientHeight / 2;

          container.scrollTo({
            top: isMobile ? scrollTop : element.offsetTop,
            behavior: "auto",
          });
        }
      }
    }
  }, [containerRef, initialSelectedOption, isMobile, options, optionsRef]);

  return (
    <>
      {options.map((option, index) => (
        <Option
          key={index}
          ref={(el) => (optionsRef.current[index] = el)}
          option={option}
          isChecked={value === option.value}
          onChange={onChange}
          renderOption={renderOption}
          onClick={onClose}
        />
      ))}
    </>
  );
}
