import { Button, Flex, Stack, Text, useDisclosure } from "@chakra-ui/react";
import { BOOKINGS_WORKFLOW_VALUE_DIRECT } from "../../../../../constants/bookings";
import { useIntl } from "react-intl";
import { Icon } from "../../../../../components/Icon";
import { BookingDiscount } from "./BookingDiscount";
import { CreateOrUpdateBookingDiscountModal } from "./CreateOrUpdateBookingDiscountModal";

/**
 * @typedef {object} Props
 * @property {keyof typeof import("../../../../../constants/carts").CARTS_WORKFLOWS} workflow
 * @property {number} bookingIndex
 * @property {boolean} displayAddButton
 * @property {boolean} isLoading
 * @property {import("react-hook-form").FieldArrayWithId<import("../../../../../types/Cart").CartCreateDataFormValues, "data.bookings.0.discounts", "id">[]} discounts
 * @property {import("react-hook-form").UseFieldArrayAppend<import("../../../../../types/Cart").CartCreateDataFormValues, any>} discountsAppend
 * @property {import("react-hook-form").UseFieldArrayRemove} discountsRemove
 * @property {import("react-hook-form").UseFieldArrayUpdate<import("../../../../../types/Cart").CartCreateDataFormValues, any>} discountsUpdate
 * @property {(data?: import("../useBookingEstimate").RequestEstimateParams) => void} requestEstimate
 * @property {import("../../../../../types/Cart").CartCreateBookingDiscount[] | null} [temporaryDiscountList]
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../../../../types/Cart").CartCreateBookingDiscount[] | null>>} [setTemporaryDiscountList]
 */

/**
 * @param {Props} props
 */
export function BookingDiscounts({
  workflow,
  bookingIndex,
  displayAddButton,
  isLoading,
  discounts,
  discountsAppend,
  discountsRemove,
  discountsUpdate,
  requestEstimate,
  temporaryDiscountList,
  setTemporaryDiscountList,
}) {
  const {
    isOpen: isOpenCreateModal,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();

  const intl = useIntl();

  function onModalSuccess(discount) {
    discountsAppend({
      discount_id: "",
      amount: Number(discount.amount),
      ...discount,
    });

    requestEstimate();
  }

  // function handleRefuseNewDiscountList() {
  //   setTemporaryDiscountList?.(null);
  // }

  // function handleAcceptNewDiscountList() {
  //   if (temporaryDiscountList) {
  //     for (const discount of temporaryDiscountList) {
  //       discountsAppend(discount);
  //     }
  //     setTemporaryDiscountList?.(null);
  //     requestEstimate();
  //   }
  // }

  return (
    <>
      {/** 
         temporaryDiscountList && (
         Laisser l'alert en commentaire tant que nous n'avons pas de solutions pour ajouter les promotions 
          <Alert
            status="info"
            display="flex"
            flexDir="column"
            alignItems="flex-start">
            <AlertDescription mb="1rem">
              {intl.formatMessage(
                {
                  defaultMessage:
                    "De nouvelles promotions sont disponibles pour cette réservation. Vous pouvez décider de les ajouter aux promotions existante de manière automatique en <i>appliquant les changements</i>. Ou ne rien mettre à jour en <i>refusant les changements</i>.",
                },
                {
                  i: (...chunks) => <i>{chunks}</i>,
                },
              )}
            </AlertDescription>

            <ButtonGroup>
              <Button colorScheme="red" onClick={handleRefuseNewDiscountList}>
                {intl.formatMessage({
                  defaultMessage: "Refuser les changements",
                })}
              </Button>

              <Button colorScheme="green" onClick={handleAcceptNewDiscountList}>
                {intl.formatMessage({
                  defaultMessage: "Appliquer les changements",
                })}
              </Button>
            </ButtonGroup>
          </Alert>
      )
        */}

      <Stack spacing="1rem">
        {discounts.length > 0 && (
          <Text variant="h5" color="gray.500">
            {intl.formatMessage({ defaultMessage: "Promotions associées" })}
          </Text>
        )}

        <Flex
          flexDir="column"
          gap=".25rem"
          opacity={isLoading ? ".5" : "1"}
          pointerEvents={isLoading ? "none" : undefined}>
          {discounts.map((discount, i) => (
            <BookingDiscount
              key={discount.id}
              discount={discount}
              discountUpdate={discountsUpdate}
              discountRemove={discountsRemove}
              bookingIndex={bookingIndex}
              discountIndex={i}
              workflow={workflow}
              requestEstimate={requestEstimate}
            />
          ))}
        </Flex>

        {workflow === BOOKINGS_WORKFLOW_VALUE_DIRECT && displayAddButton && (
          <Flex justifyContent="flex-end">
            <Button
              maxWidth="14rem"
              variant="outline"
              size="sm"
              onClick={onOpenCreateModal}
              leftIcon={<Icon icon="ms_add" />}>
              {intl.formatMessage({
                defaultMessage: "Ajouter une promotion",
              })}
            </Button>
          </Flex>
        )}

        <CreateOrUpdateBookingDiscountModal
          isOpen={isOpenCreateModal}
          onClose={onCloseCreateModal}
          onSuccess={onModalSuccess}
        />
      </Stack>
    </>
  );
}
