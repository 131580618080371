import { AspectRatio, Box, HStack, Stack, Text } from "@chakra-ui/react";
import generateFrontPath from "../../../libraries/utils/generateFrontPath";
import PampaImage from "../../PampaImage";

const PLACEHOLDER_SRC = generateFrontPath({
  id: "@front.internal-assets",
  parameters: { filePath: "placeholder-hotel.svg" },
  includeBasePath: true,
});

/**
 * @typedef {object} RoomsSelectOptionData
 * @property {string} title
 * @property {string} name
 * @property {import("../../../types/RoomPicture").RoomPicture | null} picture
 */

/**
 * @param {object} data
 */
export function renderRoomSelectOption(data) {
  return (
    <HStack>
      <Box flexShrink={0}>
        <AspectRatio ratio={1} w="40px">
          <PampaImage
            image={data.picture}
            mode="crop"
            format="square"
            placeholderSrc={PLACEHOLDER_SRC}
            maxContainerWidth={40}
            alt=""
          />
        </AspectRatio>
      </Box>
      <Stack spacing="0">
        <Text variant="text-md" noOfLines={1}>
          {data.title}
        </Text>
        <Text variant="text-sm" noOfLines={1}>
          {data.name}
        </Text>
      </Stack>
    </HStack>
  );
}
