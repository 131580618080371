import {
  IoInformationCircle,
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoWarningOutline,
} from "react-icons/io5";
import { defineMessage } from "react-intl";

export const CARTS_BOOKINGS_MESSAGES_SORT_VALUE_CREATED_ASC = "created_asc";
export const CARTS_BOOKINGS_MESSAGES_SORT_VALUE_CREATED_DESC = "created_desc";

export const CARTS_BOOKINGS_MESSAGES_SORTS = {
  [CARTS_BOOKINGS_MESSAGES_SORT_VALUE_CREATED_ASC]: {
    id: CARTS_BOOKINGS_MESSAGES_SORT_VALUE_CREATED_ASC,
  },
  [CARTS_BOOKINGS_MESSAGES_SORT_VALUE_CREATED_DESC]: {
    id: CARTS_BOOKINGS_MESSAGES_SORT_VALUE_CREATED_DESC,
  },
};

export const CARTS_BOOKING_MESSAGES_USER_TYPE_CUSTOMER = "customer";
export const CARTS_BOOKING_MESSAGES_USER_TYPE_ADMIN = "admin";
export const CARTS_BOOKING_MESSAGES_USER_TYPE_GUEST = "guest";

export const CARTS_BOOKING_MESSAGES_USER_TYPES = {
  [CARTS_BOOKING_MESSAGES_USER_TYPE_CUSTOMER]: {
    id: CARTS_BOOKING_MESSAGES_USER_TYPE_CUSTOMER,
  },
  [CARTS_BOOKING_MESSAGES_USER_TYPE_ADMIN]: {
    id: CARTS_BOOKING_MESSAGES_USER_TYPE_ADMIN,
  },
  [CARTS_BOOKING_MESSAGES_USER_TYPE_GUEST]: {
    id: CARTS_BOOKING_MESSAGES_USER_TYPE_GUEST,
  },
};

export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_MESSAGE = "message";

export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_NOTIFICATION =
  "notification";

export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_UPDATE =
  "booking_update";

export const CARTS_BOOKINGS_MESSAGES_TYPES = {
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_MESSAGE]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_MESSAGE,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_NOTIFICATION]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_NOTIFICATION,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_UPDATE]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_UPDATE,
  },
};

export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_CONFIRMED =
  "booking_confirmed";
export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_UPDATED =
  "booking_updated";
export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_CANCELLED =
  "booking_cancelled";
export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_AMOUNT_ON_SPOT_UPDATED =
  "booking_amount_on_spot_updated";
export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ACKNOWLEDGMENT_ACCEPTED =
  "booking_acknowledgment_accepted";
export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ACKNOWLEDGMENT_REFUSED =
  "booking_acknowledgment_refused";
export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ISSUE_OPENED =
  "booking_issue_opened";
export const CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ISSUE_CLOSED =
  "booking_issue_closed";

// Payload types only, no messages or notification here
export const CARTS_BOOKINGS_MESSAGES_PAYLOAD_TYPES = {
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_UPDATE]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_UPDATE,
    icon: IoInformationCircle,
    colors: ["brandPrimary.50", "brandPrimary.900"],
  },

  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_CONFIRMED]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_CONFIRMED,
    colors: ["green.50", "green.800"],
    icon: IoCheckmarkCircleOutline,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_UPDATED]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_UPDATED,
    colors: ["brandPrimary.50", "brandPrimary.900"],
    icon: IoInformationCircle,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_CANCELLED]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_CANCELLED,
    colors: ["red.50", "red.800"],
    icon: IoCloseCircleOutline,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_AMOUNT_ON_SPOT_UPDATED]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_AMOUNT_ON_SPOT_UPDATED,
    colors: ["brandPrimary.50", "brandPrimary.900"],
    icon: IoInformationCircle,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ACKNOWLEDGMENT_ACCEPTED]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ACKNOWLEDGMENT_ACCEPTED,
    colors: ["green.50", "green.800"],
    icon: IoCheckmarkCircleOutline,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ACKNOWLEDGMENT_REFUSED]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ACKNOWLEDGMENT_REFUSED,
    colors: ["red.50", "red.800"],
    icon: IoCloseCircleOutline,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ISSUE_OPENED]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ISSUE_OPENED,
    colors: ["purple.50", "purple.900"],
    icon: IoWarningOutline,
  },
  [CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ISSUE_CLOSED]: {
    id: CARTS_BOOKINGS_MESSAGES_TYPE_VALUE_BOOKING_ISSUE_CLOSED,
    colors: ["purple.50", "purple.900"],
    icon: IoWarningOutline,
  },
};
// Key for recursivity in BookingUpdate & BookingUpdated Messages
export const CARTS_BOOKINGS_MESSAGES_SERVICES_KEY = "services";

export const cartsBookingMessageBookingUpdatedMessage = defineMessage({
  defaultMessage: `{field, select,
    checkin {Date d'arrivée}
    checkout {Date de départ}
    adults {Adulte}
    children {Enfant}
    babies {Bébé}
    pets {Animal}
    periods_amount {Montant des nuits}
    amount {Tarif}
    quantity {Quantité}
    name {Nom}
    discount {Remise}
    services {Suppléments}
    other {{field}}
  }`,
});

export const CARTS_BOOKINGS_MESSAGES_STATES_LIST = Object.values(
  CARTS_BOOKINGS_MESSAGES_PAYLOAD_TYPES,
);

export const maxNumberOfCharacterForTextarea = 3000;
