import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} onRemove
 */

/**
 * @param {Props} props
 */
export function BookingRemoveConfirmationModal({ isOpen, onClose, onRemove }) {
  const intl = useIntl();

  function handleRemove() {
    onRemove();
    onClose();
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      blockScrollOnMount={false}>
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          {intl.formatMessage({ defaultMessage: "Supprimer la réservation" })}
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <Text>
            {intl.formatMessage({
              defaultMessage:
                "Êtes vous sur de vouloir supprimer cette réservation",
            })}
          </Text>
        </ModalBody>

        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" onClick={onClose}>
              {intl.formatMessage({
                defaultMessage: "Annuler",
              })}
            </Button>

            <Button colorScheme="red" onClick={handleRemove}>
              {intl.formatMessage({
                defaultMessage: "Supprimer",
              })}
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
