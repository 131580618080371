import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useRef } from "react";
import { defineMessage, FormattedMessage, useIntl } from "react-intl";
import { Icon } from "../../../components/Icon";

export const ROOMS_VISUALIZATION_LEGEND_ITEMS = [
  {
    color: "brandPrimary.500",
    label: defineMessage({
      defaultMessage: "Jour d'arrivé ou de départ possible",
    }),
    difficultContrast: false,
  },
  {
    color: "brandPrimary.300",
    label: defineMessage({ defaultMessage: "Jour de séjour possible" }),
    difficultContrast: false,
  },
  {
    color: "rgba(0,0,0,.25)",
    label: defineMessage({ defaultMessage: "Jour indisponible" }),
    difficultContrast: true,
  },
];

export function Legend() {
  const intl = useIntl();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const ref = useRef(/** @type {any | null} */ (null));

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<Icon icon="ms_help" />}
        variant="outline">
        <Text fontWeight="normal">
          <FormattedMessage defaultMessage="Légende" />
        </Text>
      </Button>

      <Modal
        initialFocusRef={ref}
        isOpen={isOpen}
        onClose={onClose}
        scrollBehavior="inside">
        <ModalOverlay />

        <ModalContent mx="1rem">
          <ModalHeader>
            <FormattedMessage defaultMessage="Légende" />
          </ModalHeader>

          <ModalCloseButton />

          <ModalBody>
            <Stack spacing="4px">
              {ROOMS_VISUALIZATION_LEGEND_ITEMS.map((item, index) => (
                <Box key={index} display="flex" alignItems="center" mr="16px">
                  <Box
                    w="14px"
                    h="14px"
                    borderRadius="full"
                    bg={item.color}
                    mr="8px"
                    borderWidth="1px"
                    borderColor={item.difficultContrast ? "#ccc" : item.color}
                  />

                  {intl.formatMessage(item.label)}
                </Box>
              ))}
            </Stack>
          </ModalBody>

          <ModalFooter>
            <Button ref={ref} onClick={onClose} colorScheme="brandSecondary">
              <FormattedMessage defaultMessage="Fermer" />
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
