/* eslint-disable jsdoc/valid-types */
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useMemo, useRef } from "react";
import { typedForwardRef } from "../../../hocs/typedForwardRef";
import { typedMemo } from "../../../hocs/typedMemo";
import { Icon } from "../../Icon";
import { Body } from "./Body";

/**
 * @template TValue, TData
 * @typedef {object} SelectOption
 * @property {TValue} value
 * @property {TData} data
 * @property {string} label
 */
/**
 * @template TValue, TData
 * @typedef {object} Props
 * @property {SelectOption<TValue, TData>[]} options
 * @property {TValue} value
 * @property {(event: { target: { name: string, value: TValue | "" } }) => void} onChange
 * @property {(data: TData) => JSX.Element} [renderOption]
 * @property {boolean} isInvalid
 * @property {boolean} isRequired
 */

export const Select = typedMemo(
  typedForwardRef(
    /**
     * @template TValue, TData
     * @param {Props<TValue, TData> & Omit<import("@chakra-ui/react").ButtonProps, "onChange">} props
     */
    function Select(
      { options, value, onChange, renderOption, ..._otherProps },
      ref,
    ) {
      const otherProps = useMemo(() => {
        /** @type {any} */
        const otherProps = { ..._otherProps };
        delete otherProps.isInvalid;
        delete otherProps.isRequired;
        return otherProps;
      }, [_otherProps]);

      const selectedOption = useMemo(() => {
        return options.find((o) => o.value === value);
      }, [options, value]);

      const isMobile = useBreakpointValue({ base: true, sm: false }) ?? false;

      const { isOpen, onOpen, onClose } = useDisclosure();

      const optionsRef = useRef(/** @type {HTMLDivElement[]} */ ([]));

      const modalBodyRef = useRef(/** @type {HTMLDivElement | null} */ (null));

      const popoverContentRef = useRef(
        /** @type {HTMLDivElement | null} */ (null),
      );

      return (
        <>
          <Popover
            isLazy={true}
            lazyBehavior="unmount"
            isOpen={isOpen}
            onClose={onClose}
            matchWidth={true}>
            <PopoverTrigger>
              <Button
                ref={ref}
                rightIcon={<Icon icon="ms_keyboard_arrow_down" />}
                justifyContent="flex-start"
                onClick={onOpen}
                {...otherProps}>
                <Text
                  textAlign="left"
                  fontWeight="normal"
                  fontSize="1rem"
                  flexGrow={1}
                  textOverflow="ellipsis"
                  overflow="hidden">
                  {selectedOption ? selectedOption.label : "-"}
                </Text>
              </Button>
            </PopoverTrigger>

            {!isMobile && (
              <PopoverContent
                ref={popoverContentRef}
                maxW={otherProps.w ?? otherProps.width}
                maxH="45dvh"
                overflowY="auto"
                borderRadius="10px">
                <PopoverArrow />

                <PopoverBody p="0">
                  <Body
                    options={options}
                    value={value}
                    onChange={onChange}
                    renderOption={renderOption}
                    selectedOption={selectedOption}
                    optionsRef={optionsRef}
                    onClose={onClose}
                    isMobile={isMobile}
                    containerRef={popoverContentRef}
                  />
                </PopoverBody>
              </PopoverContent>
            )}
          </Popover>

          {isMobile && (
            <Modal
              isOpen={isOpen}
              onClose={onClose}
              scrollBehavior="inside"
              size="xs">
              <ModalOverlay />

              <ModalContent mx="1rem">
                <ModalCloseButton backgroundColor="gray.100" />

                <ModalBody
                  ref={modalBodyRef}
                  px="0"
                  py="0"
                  pt="48px"
                  overflowY="auto">
                  <Body
                    options={options}
                    value={value}
                    onChange={onChange}
                    renderOption={renderOption}
                    selectedOption={selectedOption}
                    optionsRef={optionsRef}
                    onClose={onClose}
                    isMobile={isMobile}
                    containerRef={modalBodyRef}
                  />
                </ModalBody>
              </ModalContent>
            </Modal>
          )}
        </>
      );
    },
  ),
);
