import {
  Alert,
  AlertDescription,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import ApiErrorMessage from "../../../components/ApiErrorMessage";
import { Bookings } from "./booking/Bookings";
import { BookingFormCustomer } from "./customer/Form";
import { CartSummary } from "./summary/CartSummary";

/**
 * @typedef {object} Props
 * @property {import("../../../types/Cart").BookingSummary[]} bookingSummaries
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../../types/Cart").BookingSummary[]>>} setBookingSummaries
 * @property {number} tabIndex
 * @property {(index: number) => void} setSelectedTab
 * @property {keyof typeof import("../../../constants/carts").CARTS_WORKFLOWS} workflow
 * @property {import("react-hook-form").UseFormReturn<import("../../../types/Cart").CartCreateDataFormValues>} form
 * @property {import("react").FC<{onSuccess: (clientId?: number) => void}> | null} ActionCreateCustomerComponent
 */

/**
 * @param {Props} props
 */
export function Form({
  bookingSummaries,
  setBookingSummaries,
  tabIndex,
  setSelectedTab,
  workflow,
  ActionCreateCustomerComponent,
}) {
  const intl = useIntl();

  /** @type {import("react-hook-form").UseFormReturn<import("../../../types/Cart").CartCreateDataFormValues>} */
  const form = useFormContext();

  const step1HasError = ["bookings"].some(function (name) {
    if (form.formState?.errors?.data) {
      return Boolean(
        Object.prototype.hasOwnProperty.call(
          form.formState?.errors?.data,
          name,
        ),
      );
    }
  });

  const step2HasError = ["main_guest"].some(function (name) {
    if (form.formState?.errors?.data) {
      return Boolean(
        Object.prototype.hasOwnProperty.call(
          form.formState?.errors?.data,
          name,
        ),
      );
    }
  });

  const tabsBgColor = useColorModeValue("white", "gray.800");

  return (
    <>
      <ApiErrorMessage withCaughtErrors={true} />

      <VStack spacing="1rem" align="stretch">
        <Tabs
          index={tabIndex}
          onChange={(index) => setSelectedTab(index)}
          variant="soft-rounded">
          <Stack
            gap=".5rem"
            position="sticky"
            top="-8px"
            background={tabsBgColor}
            zIndex="1"
            pb=".625rem">
            {((step1HasError && tabIndex !== 0) ||
              (step2HasError && tabIndex !== 1)) && (
              <Alert status="error">
                <AlertDescription>
                  {intl.formatMessage(
                    {
                      defaultMessage:
                        "Un ou plusieurs champs n'ont pas étés remplis dans l'onglet « {tab} » .",
                    },
                    {
                      tab: step1HasError
                        ? intl.formatMessage({ defaultMessage: "Réservation" })
                        : intl.formatMessage({
                            defaultMessage: "Coordonnées du client",
                          }),
                    },
                  )}
                </AlertDescription>
              </Alert>
            )}

            <TabList>
              <Tab color={step1HasError ? "red.500" : undefined}>
                {intl.formatMessage({
                  defaultMessage: "Réservation",
                })}
              </Tab>

              <Tab color={step2HasError ? "red.500" : undefined}>
                {intl.formatMessage({
                  defaultMessage: "Coordonnées du client",
                })}
              </Tab>

              <Tab>
                <Text color="gray.600">
                  {intl.formatMessage({
                    defaultMessage: "Résumé",
                  })}
                </Text>
              </Tab>
            </TabList>
          </Stack>

          <TabPanels py="1rem">
            <TabPanel p="0">
              <Bookings
                workflow={workflow}
                setBookingSummaries={setBookingSummaries}
                bookingSummaries={bookingSummaries}
                form={form}
              />
            </TabPanel>

            <TabPanel p="0">
              <BookingFormCustomer
                ActionCreateCustomerComponent={ActionCreateCustomerComponent}
                workflow={workflow}
              />
            </TabPanel>

            <TabPanel p="0">
              <CartSummary
                data={form.getValues("data")}
                bookingSummaries={bookingSummaries}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>
    </>
  );
}
