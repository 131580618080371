import {
  Box,
  UnorderedList,
  ListItem,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  CARTS_BOOKINGS_MESSAGES_SERVICES_KEY,
  cartsBookingMessageBookingUpdatedMessage,
} from "../../../../../constants/cartBookingMessage";
import { useIntl } from "react-intl";
import {
  CURRENCY_OPTIONS,
  SHORT_DATE_OPTIONS,
} from "../../../../../constants/intl";
/**
 * @typedef Props
 * @property {object} diffObject
 */
/**
 * @returns {import("react").FunctionComponentElement<Props>}
 */
export default function BookingUpdateRecursiveDiffList({ diffObject }) {
  const intl = useIntl();

  const fieldsMoney = ["amount", "periods_amount", "discount"];
  const fieldsNumber = ["adults", "children", "babies", "pets"];
  const fieldsDate = ["checkin", "checkout"];

  const color = useColorModeValue("gray.200", "gray.700");
  const formatValue = (value, key) => {
    if (value === null || value === undefined || !key) {
      return "";
    }

    let valueAsString = value?.toString();
    const maxNbrOfCharacters = 20;

    // number
    if (fieldsNumber.includes(key)) {
      return valueAsString;
    }

    // dates
    if (fieldsDate.includes(key)) {
      return intl.formatDate(valueAsString, SHORT_DATE_OPTIONS);
    }

    // money
    if (fieldsMoney.includes(key)) {
      return intl.formatNumber(value, CURRENCY_OPTIONS);
    }

    // long text
    if (valueAsString?.length > maxNbrOfCharacters) {
      valueAsString = `${valueAsString.slice(
        0,
        maxNbrOfCharacters - 1,
      )}...`.trim();
    }

    return valueAsString ?? "";
  };

  return (
    diffObject && (
      <UnorderedList>
        {Object.entries(diffObject).map(([key, value], index) => {
          return value !== null && value !== undefined ? (
            typeof value === "object" ? (
              <Box mb=".5rem" key={`${index} + ${key}`}>
                {key !== CARTS_BOOKINGS_MESSAGES_SERVICES_KEY ? (
                  <Text colorScheme={color}>
                    {isNaN(Number(key))
                      ? `${intl.formatMessage(
                          cartsBookingMessageBookingUpdatedMessage,
                          { field: key },
                        )} : `
                      : ""}
                  </Text>
                ) : (
                  value.some((v) => v) && (
                    <Box>
                      {`${intl.formatMessage(
                        cartsBookingMessageBookingUpdatedMessage,
                        { field: "services" },
                      )} : `}
                      {value.map((singleValue, index) => (
                        <Text
                          key={index}
                          display={index === 0 ? "inline" : "block"}>
                          {intl.formatMessage(
                            { defaultMessage: "{quantity} x {name} ({total})" },
                            {
                              quantity: singleValue?.quantity,
                              name: singleValue?.name,
                              total: intl.formatNumber(
                                singleValue?.amount * singleValue?.quantity,
                                CURRENCY_OPTIONS,
                              ),
                            },
                          )}
                        </Text>
                      ))}
                    </Box>
                  )
                )}
              </Box>
            ) : (
              <ListItem display="flex" gap=".25rem" key={`${index} + ${key}`}>
                <Text colorScheme={color}>
                  {`${intl.formatMessage(
                    cartsBookingMessageBookingUpdatedMessage,
                    { field: key },
                  )} : `}
                </Text>
                <Text>{formatValue(value, key)}</Text>
              </ListItem>
            )
          ) : null;
        })}
      </UnorderedList>
    )
  );
}
