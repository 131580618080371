import { Button, ButtonGroup, ModalBody, ModalFooter } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import generateApiUri from "../../../libraries/utils/generateApiUrl";
import { Form } from "../Form";
import { useApiError } from "../../../components/ReactHookForm/ApiError";
import FormStateRHF from "../../../components/ReactHookForm/FormStateRHF";
import { BOOKINGS_WORKFLOW_VALUE_DIRECT } from "../../../constants/bookings";
import { CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR } from "../../../constants/customerTravelers";
import useRequest from "../../../hooks/useRequest";
import cartsSerializeCreate from "../../../normalizers/carts/serializeCreate";
import { useState } from "react";
import { createBookingWithDefaultValue } from "./createBookingWithDefaultValue";
import getBookingSummary from "../../../helpers/bookings/getBookingSummary";

/**
 * @param {object} param0
 * @param {number} [param0.roomId]
 * @param {string} [param0.checkin]
 * @param {string} [param0.checkout]
 * @param {number} [param0.adults]
 * @param {number} [param0.babies]
 * @param {number} [param0.children]
 * @param {number} [param0.pets]
 * @returns {import("../../../types/Cart").CartCreateDataFormValues}
 */
function getDefaultValues({
  roomId,
  checkin,
  checkout,
  adults,
  babies,
  children,
  pets,
}) {
  return {
    data: {
      bookings: [
        createBookingWithDefaultValue({
          roomId,
          checkin,
          checkout,
          adults,
          babies,
          children,
          pets,
        }),
      ],
      main_guest: {
        customer_id: "",
        type: CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR,
        gender: "",
        firstname: "",
        lastname: "",
        birthdate: "",
        address: "",
        address2: "",
        city: "",
        zipcode: "",
        country_id: 67,
        phone1: "",
        phone1_country: "FR",
        phone2: "",
        phone2_country: "FR",
        phone3: "",
        phone3_country: "FR",
        email: "",
        siret: "",
        vat_number: "",
        company: "",
        infos: "",
      },
      guests: [],
    },
  };
}

/**
 * @typedef Props
 * @property {() => void} onClose
 * @property {(cartId: number, bookingId: number) => void} [onSuccess]
 * @property {number} [roomId]
 * @property {string} [checkin]
 * @property {string} [checkout]
 * @property {number} [adults]
 * @property {number} [babies]
 * @property {number} [children]
 * @property {number} [pets]
 * @property {keyof typeof import("../../../constants/carts").CARTS_WORKFLOWS} [workflow]
 * @property {import("react").FC<{onSuccess: (clientId?: number) => void}> | null} ActionCreateCustomerComponent
 */

/**
 * @param {Props} props
 * @returns {import("react").ReactElement}
 */
export function Handler({
  onClose,
  onSuccess,
  roomId,
  checkin,
  checkout,
  adults,
  babies,
  children,
  pets,
  workflow = BOOKINGS_WORKFLOW_VALUE_DIRECT,
  ActionCreateCustomerComponent,
}) {
  const intl = useIntl();

  const { request, isLoading, toastSuccess } = useRequest();

  const { resolveApiError, resetApiErrors } = useApiError();

  const [selectedTab, setSelectedTab] = useState(0);

  const defaultValues = getDefaultValues({
    roomId,
    checkin,
    checkout,
    adults,
    babies,
    children,
    pets,
  });

  const form = useForm({
    defaultValues,
  });
  const { handleSubmit } = form;

  const [bookingSummaries, setBookingSummaries] = useState(
    /** @type {import("../../../types/Cart").BookingSummary[]} */
    [
      getBookingSummary({
        data: null,
        bookingFormValues: defaultValues.data?.bookings[0],
      }),
    ],
  );

  /**
   * Gère la soumission du formulaire.
   */
  function onSubmit(fields) {
    request(
      generateApiUri({
        id: "@carts.create",
      }),
      {
        method: "POST",
        body: cartsSerializeCreate({ fields, workflow }),
      },
    )
      .then(function (response) {
        resetApiErrors();

        toastSuccess({
          title: intl.formatMessage({
            defaultMessage: "Nouvelle réservation",
          }),
          description: intl.formatMessage({
            defaultMessage: "La réservation a été créée avec succès.",
          }),
        });

        onSuccess?.(response?.data?.id, response?.data?.bookings[0]?.id);
        onClose();
      })

      .catch((response) => {
        resolveApiError({ response, form });
      });
  }

  /**
   * Gère le clic sur l’étape suivante.
   */
  function handleNextStep() {
    setSelectedTab(function (tabIndex) {
      return tabIndex + 1;
    });
  }

  /**
   * Gère le clic sur l’étape précédente.
   */
  function handlePreviousStep() {
    setSelectedTab(function (tabIndex) {
      return tabIndex - 1;
    });
  }

  return (
    <FormProvider {...form}>
      <ModalBody>
        <form
          id="createBookings"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          noValidate>
          <Form
            bookingSummaries={bookingSummaries}
            setBookingSummaries={setBookingSummaries}
            setSelectedTab={setSelectedTab}
            tabIndex={selectedTab}
            workflow={workflow}
            form={form}
            ActionCreateCustomerComponent={ActionCreateCustomerComponent}
          />
        </form>
      </ModalBody>

      <ModalFooter>
        <ButtonGroup>
          <Button variant="outline" onClick={onClose}>
            {intl.formatMessage({
              defaultMessage: "Annuler",
            })}
          </Button>

          {selectedTab > 0 && (
            <Button
              variant="outline"
              colorScheme="gray"
              isDisabled={isLoading}
              onClick={handlePreviousStep}>
              {intl.formatMessage({
                defaultMessage: "Étape précédente",
              })}
            </Button>
          )}

          {selectedTab < 2 && (
            <Button
              variant="outline"
              colorScheme="gray"
              isDisabled={isLoading || selectedTab === 1}
              onClick={handleNextStep}>
              {intl.formatMessage({
                defaultMessage: "Étape suivante",
              })}
            </Button>
          )}

          <FormStateRHF
            render={({ isDirty }) => (
              <Button
                form="createBookings"
                type="submit"
                isLoading={isLoading}
                isDisabled={!isDirty}
                colorScheme="brandPrimary">
                {intl.formatMessage({
                  defaultMessage: "Enregistrer",
                })}
              </Button>
            )}
          />
        </ButtonGroup>
      </ModalFooter>
    </FormProvider>
  );
}
