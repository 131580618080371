import { Flex, Stack, Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { BookingCharge } from "./BookingCharge";

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").FieldArrayWithId<import("../../../../../types/Cart").CartCreateDataFormValues, "data.bookings.0.charges", "id">[]} charges
 * @property {boolean} isLoading
 */

/**
 * @param {Props} props
 */
export function BookingCharges({ charges, isLoading }) {
  const intl = useIntl();

  return (
    <>
      <Stack spacing="1rem">
        {charges.length > 0 && (
          <Text variant="h5" color="gray.500">
            {intl.formatMessage({ defaultMessage: "Charges" })}
          </Text>
        )}

        <Flex
          flexDir="column"
          gap=".25rem"
          opacity={isLoading ? ".5" : "1"}
          pointerEvents={isLoading ? "none" : undefined}>
          {charges.map((charge) => (
            <BookingCharge key={charge.id} charge={charge} />
          ))}
        </Flex>
      </Stack>
    </>
  );
}
