import { Button, HStack, Text } from "@chakra-ui/react";
import { forwardRef, useCallback } from "react";
import { initNumber } from "../../../helpers/form/initNumber";

export const NumberIncrementer = forwardRef(
  /**
   * @typedef {object} Props
   * @property {number} [min]
   * @property {number} [max]
   */
  /**
   * @param {import("../../../components/ReactHookForm/FormControlRHF").FieldProps<number> & Props} props
   */
  function NumberIncrementer(
    {
      name,
      value: _value,
      onChange: _onChange,
      isDisabled,
      min = 0,
      max = Infinity,
    },
    ref,
  ) {
    const value = initNumber(_value);

    const onChange = useCallback(
      /**
       * @param {number} value
       */
      (value) => {
        _onChange({ target: { name, value } });
      },
      [_onChange, name],
    );

    const isMinusDisabled = value <= min;
    const isPlusDisabled = value >= max;

    return (
      <HStack>
        <HStack spacing=".5rem" pl="1.5rem">
          <Button
            ref={isMinusDisabled ? undefined : isPlusDisabled ? ref : undefined}
            onClick={() => {
              onChange(Math.max(value - 1, 0));
            }}
            isDisabled={isMinusDisabled || isDisabled}
            variant="outline"
            borderRadius="full">
            -
          </Button>

          <Text
            fontSize="1rem"
            fontWeight={400}
            lineHeight="1.3125rem"
            textAlign="center"
            minW="1.5rem"
            color={value > 0 ? undefined : "gray.500"}>
            {value}
          </Text>

          <Button
            ref={isPlusDisabled ? undefined : ref}
            onClick={() => {
              onChange(value + 1);
            }}
            isDisabled={isPlusDisabled || isDisabled}
            variant="outline"
            borderRadius="full">
            +
          </Button>
        </HStack>
      </HStack>
    );
  },
);
