/**
 * Returns true if the booking has all the required fields to request an estimate
 * @param {object} param0
 * @param {import("../../../../types/Cart").CartCreateOrUpdateBooking} param0.booking
 */

export default function canRequestEstimate({ booking }) {
  return (
    Boolean(booking?.adults) &&
    Boolean(booking?.room_id) &&
    Boolean(booking?.checkin) &&
    Boolean(booking?.checkout)
  );
}
