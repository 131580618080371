import { defineMessage } from "react-intl";
import {
  POLICIES_POLICY_VALUE_DELETE,
  POLICIES_POLICY_VALUE_SOFT_DELETE,
} from "./policies";

/* Tri */
export const ROOMS_SERVICES_SORT_VALUE_CREATED_ASC = "created_asc";
export const ROOMS_SERVICES_SORT_VALUE_CREATED_DESC = "created_desc";
export const ROOMS_SERVICES_SORT_VALUE_NAME_ASC = "name_asc";
export const ROOMS_SERVICES_SORT_VALUE_NAME_DESC = "name_desc";

export const ROOMS_SERVICES_SORTS = {
  [ROOMS_SERVICES_SORT_VALUE_CREATED_ASC]: {
    id: ROOMS_SERVICES_SORT_VALUE_CREATED_ASC,
  },
  [ROOMS_SERVICES_SORT_VALUE_CREATED_DESC]: {
    id: ROOMS_SERVICES_SORT_VALUE_CREATED_DESC,
  },
  [ROOMS_SERVICES_SORT_VALUE_NAME_ASC]: {
    id: ROOMS_SERVICES_SORT_VALUE_NAME_ASC,
  },
  [ROOMS_SERVICES_SORT_VALUE_NAME_DESC]: {
    id: ROOMS_SERVICES_SORT_VALUE_NAME_DESC,
  },
};

export const ROOMS_SERVICES_SORT_LIST = Object.values(ROOMS_SERVICES_SORTS);

export const roomsServicesSortMessage = defineMessage({
  defaultMessage:
    "{sort, select, created_asc {Date de création croissante} created_desc {Date de création décroissante} name_asc {Nom croissant} name_desc {Nom décroissant} other {{sort}}}",
});

export const ROOMS_SERVICES_STRATEGY_VALUE_PERSON = "person";
export const ROOMS_SERVICES_STRATEGY_VALUE_PERSON_NIGHT = "person_night";
export const ROOMS_SERVICES_STRATEGY_VALUE_PERSON_DAY = "person_day";
export const ROOMS_SERVICES_STRATEGY_VALUE_TRIP = "trip";
export const ROOMS_SERVICES_STRATEGY_VALUE_DAY = "day";
export const ROOMS_SERVICES_STRATEGY_VALUE_NIGHT = "night";
export const ROOMS_SERVICES_STRATEGY_VALUE_PET = "pet";
export const ROOMS_SERVICES_STRATEGY_VALUE_PET_NIGHT = "pet_night";
export const ROOMS_SERVICES_STRATEGY_VALUE_PET_DAY = "pet_day";
export const ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY = "free_quantity";

export const ROOMS_SERVICES_STRATEGY_VALUES = {
  [ROOMS_SERVICES_STRATEGY_VALUE_PERSON]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_PERSON,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_PERSON_NIGHT]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_PERSON_NIGHT,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_PERSON_DAY]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_PERSON_DAY,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_TRIP]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_TRIP,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_DAY]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_DAY,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_NIGHT]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_NIGHT,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_PET]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_PET,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_PET_NIGHT]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_PET_NIGHT,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_PET_DAY]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_PET_DAY,
  },
  [ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY]: {
    id: ROOMS_SERVICES_STRATEGY_VALUE_FREE_QUANTITY,
  },
};

export const ROOMS_SERVICES_STRATEGY_VALUES_LIST = Object.values(
  ROOMS_SERVICES_STRATEGY_VALUES,
);

export const roomsServicesStrategyMessage = defineMessage({
  defaultMessage: `{strategy, select,
    person {personne}
    person_night {personne par nuit}
    person_day {personne par jour}
    trip {séjour}
    day {jour}
    night {nuit}
    pet {animal}
    pet_night {animal par nuit}
    pet_day {animal par jour}
    free_quantity {quantité libre}
    other {{strategy}}
  }`,
});

export const roomServicesStrategyWithPrepositionMessage = defineMessage({
  defaultMessage: `{strategy, select,
    person {par personne}
    person_night {par personne par nuitée}
    person_day {par personne par jour}
    trip {par séjour}
    day {par jour}
    night {par nuitée}
    pet {par animal}
    pet_night {par animal par nuitée}
    pet_day {par animal par jour}
    free_quantity {par quantité libre}
    other {par {strategy}}
  }`,
});

export const ROOMS_SERVICES_AMOUNT_MODE_VALUE_PERCENT = "P";
export const ROOMS_SERVICES_AMOUNT_MODE_VALUE_EURO = "E";

export const ROOMS_SERVICES_AMOUNT_MODES = {
  [ROOMS_SERVICES_AMOUNT_MODE_VALUE_EURO]: {
    id: ROOMS_SERVICES_AMOUNT_MODE_VALUE_EURO,
  },
  [ROOMS_SERVICES_AMOUNT_MODE_VALUE_PERCENT]: {
    id: ROOMS_SERVICES_AMOUNT_MODE_VALUE_PERCENT,
  },
};

export const ROOMS_SERVICES_AMOUNT_MODE_LIST = Object.values(
  ROOMS_SERVICES_AMOUNT_MODES,
);

export const roomsServicesAmountModeMessage = defineMessage({
  defaultMessage: `{amountMode, select,
    P {pourcent}
    E {euros}
    other {{state}}
  }`,
});

export const ROOMS_SERVICES_UPDATE_SUBSCRIBE_FRAGMENT = "subscribeToRooms";

export const roomsServicesAvailabilityPeriodInfoMessage = defineMessage({
  defaultMessage:
    "La date d’entrée en location doit être comprise entre ces dates pour que ce supplément soit proposé.",
});

export const roomsServicesUpdateWarningMessage = defineMessage({
  defaultMessage:
    "Attention, toutes les modifications faites sur ce supplément seront appliquées pour tous les hébergements y étant assignés.",
});

export const roomsServicesDeleteWarningMessage = defineMessage({
  defaultMessage:
    "Attention, la suppression de ce supplément sera appliquée pour tous les hébergements.",
});

export const roomsServicesGeneralInfoMessage = defineMessage({
  defaultMessage:
    "Ne pas utiliser cette interface pour demander une taxe de séjour. En effet, la taxe de séjour sera automatiquement calculée par le système au moment de la réservation.",
});

export const roomsServicesIsChargeInfoMessage = defineMessage({
  defaultMessage:
    "En cochant cette case, vous pouvez définir que ce supplément sera une charge. Il apparaitra sur le contrat de location sans prix.",
});

export const ROOMS_SERVICES_POLICIES_ACTIONS = {
  delete: {
    somePolicies: [
      POLICIES_POLICY_VALUE_DELETE,
      POLICIES_POLICY_VALUE_SOFT_DELETE,
    ],
  },
};
