import { Box, Text } from "@chakra-ui/react";
/**
 * @typedef {object} Props
 * @property {import("../../../../../types/Cart").CartCreateBookingCharge} charge
 */

/**
 * @param {Props} props
 */
export function BookingCharge({ charge }) {
  return (
    <>
      <Box py=".5rem" background="gray.100" p=".75rem" borderRadius="8px">
        <Text fontWeight="500">{charge.name}</Text>

        <Text isTruncated={true} maxWidth="full">
          {charge.about}
        </Text>
      </Box>
    </>
  );
}
