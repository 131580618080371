import { Button, Flex, Stack } from "@chakra-ui/react";
import { useFieldArray } from "react-hook-form";
import { useIntl } from "react-intl";
import { Frame } from "../../../../components/Frame";
import getBookingSummary from "../../../../helpers/bookings/getBookingSummary";
import { createBookingWithDefaultValue } from "../../Create/createBookingWithDefaultValue";
import { BookingCard } from "./BookingCard";

/**
 * @typedef {object} Props
 * @property {keyof typeof import("../../../../constants/carts").CARTS_WORKFLOWS} workflow
 * @property {import("react").Dispatch<import("react").SetStateAction<import("../../../../types/Cart").BookingSummary[]>>} setBookingSummaries
 * @property {import("../../../../types/Cart").BookingSummary[]} bookingSummaries
 * @property {import("react-hook-form").UseFormReturn<import("../../../../types/Cart").CartCreateDataFormValues>} form
 */

/**
 * @param {Props} props
 */
export function Bookings({
  form,
  workflow,
  setBookingSummaries,
  bookingSummaries,
}) {
  const {
    fields: bookings,
    append: bookingsAppend,
    remove: bookingsRemove,
    update: bookingsUpdate,
  } = useFieldArray({ name: "data.bookings", control: form.control });

  const intl = useIntl();

  function handleCreateBooking() {
    const booking = createBookingWithDefaultValue({ roomId: undefined });
    bookingsAppend(booking);

    const bookingSummary = getBookingSummary({
      data: null,
      bookingFormValues: booking,
    });

    setBookingSummaries((previousBookingSummaries) => {
      return [...previousBookingSummaries, bookingSummary];
    });
  }

  return (
    <Stack spacing="1rem">
      {bookings.map((booking, index) => {
        return (
          <Frame key={booking.id}>
            <BookingCard
              bookingIndex={index}
              bookingsRemove={bookingsRemove}
              workflow={workflow}
              setBookingSummaries={setBookingSummaries}
              bookingsUpdate={bookingsUpdate}
              form={form}
            />
          </Frame>
        );
      })}

      <Flex width="full" justifyContent="flex-end">
        <Button
          variant="outline"
          colorScheme="brandPrimary"
          onClick={handleCreateBooking}>
          {intl.formatMessage({
            defaultMessage: "Ajouter une réservation",
          })}
        </Button>
      </Flex>
    </Stack>
  );
}
