import { Box, Flex, SimpleGrid, Text } from "@chakra-ui/react";
import { CURRENCY_OPTIONS } from "../../../../constants/intl";
import { memo } from "react";
import { useIntl } from "react-intl";

export const BookingSummary = memo(
  /**
   * @typedef {object} Props
   * @property {import("../../../../types/Cart").BookingSummary} bookingSummary
   */
  /**
   * @param {Props} props
   */
  function BookingSummary({ bookingSummary }) {
    const intl = useIntl();

    return (
      <Box background="brandPrimary.500" p=".75rem">
        <SimpleGrid
          columns={{ base: 1, lg: 2 }}
          pb={{ base: "1rem", lg: ".5rem" }}
          gap={{ base: "1rem", lg: "4rem" }}
          borderBottom="1px solid"
          borderBottomColor="brandPrimary.200">
          <Flex
            justifyContent="space-between"
            position="relative"
            gap="1rem"
            borderBottom={{ base: "1px solid", lg: "none" }}
            borderBottomColor={{ base: "brandPrimary.200", lg: "none" }}
            pb={{ base: "1rem", lg: "0" }}
            flexWrap={{ base: "wrap", lg: "initial" }}>
            <Text
              variant="h4"
              color="brandPrimary.100"
              textAlign={{ base: "center", lg: "left" }}
              width={{ base: "full", lg: "initial" }}>
              {intl.formatMessage({ defaultMessage: "Total des nuits" })}
            </Text>

            <Box>
              <Text color="brandPrimary.100">
                {intl.formatMessage({ defaultMessage: "Montant brut" })}
              </Text>

              <Text color="white">
                {!isNaN(bookingSummary?.periodsAmountRaw ?? 0)
                  ? intl.formatNumber(
                      bookingSummary?.periodsAmountRaw ?? 0,
                      CURRENCY_OPTIONS,
                    )
                  : "…"}
              </Text>
            </Box>

            <Box>
              <Text color="brandPrimary.100">
                {intl.formatMessage({ defaultMessage: "Remise" })}
              </Text>

              <Text color="white">
                {!isNaN(bookingSummary?.periodsAmountDiscount ?? 0)
                  ? `- ${intl.formatNumber(
                      bookingSummary?.periodsAmountDiscount ?? 0,
                      CURRENCY_OPTIONS,
                    )}`
                  : "…"}
              </Text>
            </Box>

            <Box>
              <Text color="brandPrimary.100">
                {intl.formatMessage({ defaultMessage: "Total" })}
              </Text>

              <Text color="white">
                {!isNaN(bookingSummary?.periodsAmount ?? 0)
                  ? `= ${intl.formatNumber(
                      bookingSummary?.periodsAmount ?? 0,
                      CURRENCY_OPTIONS,
                    )}`
                  : "…"}
              </Text>
            </Box>

            <Box
              display={{ base: "none", lg: "block" }}
              position="absolute"
              width="1px"
              background="brandPrimary.200"
              right="-2rem"
              height="full"
            />
          </Flex>

          <Flex
            justifyContent="space-between"
            gap="1rem"
            flexWrap={{ base: "wrap", lg: "initial" }}>
            <Text
              variant="h4"
              textAlign={{ base: "center", lg: "left" }}
              color="brandPrimary.100"
              width={{ base: "full", lg: "initial" }}>
              {intl.formatMessage({ defaultMessage: "Total des suppléments" })}
            </Text>

            <Box>
              <Text color="brandPrimary.100">
                {intl.formatMessage({ defaultMessage: "Montant brut" })}
              </Text>

              <Text color="white">
                {!isNaN(bookingSummary?.servicesAmountRaw ?? 0)
                  ? intl.formatNumber(
                      bookingSummary?.servicesAmountRaw ?? 0,
                      CURRENCY_OPTIONS,
                    )
                  : "…"}
              </Text>
            </Box>

            <Box>
              <Text color="brandPrimary.100">
                {intl.formatMessage({ defaultMessage: "Remise" })}
              </Text>

              <Text color="white">
                {!isNaN(bookingSummary?.servicesAmountDiscount ?? 0)
                  ? `- ${intl.formatNumber(
                      bookingSummary?.servicesAmountDiscount ?? 0,
                      CURRENCY_OPTIONS,
                    )}`
                  : "…"}
              </Text>
            </Box>

            <Box>
              <Text color="brandPrimary.100">
                {intl.formatMessage({ defaultMessage: "Total" })}
              </Text>

              <Text color="white">
                {!isNaN(bookingSummary?.servicesAmount ?? 0)
                  ? `= ${intl.formatNumber(
                      bookingSummary?.servicesAmount ?? 0,
                      CURRENCY_OPTIONS,
                    )}`
                  : "…"}
              </Text>
            </Box>
          </Flex>
        </SimpleGrid>

        <Flex
          width="full"
          justifyContent="center"
          alignItems="center"
          flexWrap={{ base: "wrap", lg: "initial" }}
          gap=".75rem"
          p=".75rem">
          <Box>
            <Text
              variant="h4"
              color="brandPrimary.100"
              width={{ base: "full", lg: "initial" }}>
              {intl.formatMessage({ defaultMessage: "Total du séjour" })}
            </Text>

            <Text variant="text-sm" color="brandPrimary.100">
              {intl.formatMessage({
                defaultMessage: "Hors taxe de séjour et frais",
              })}
            </Text>
          </Box>

          <Text variant="h2" color="white">
            {!isNaN(bookingSummary?.total ?? 0)
              ? intl.formatNumber(bookingSummary?.total ?? 0, CURRENCY_OPTIONS)
              : "…"}
          </Text>
        </Flex>
      </Box>
    );
  },
);
